import React, { useState, useEffect } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import Footer from '../layout/Footer';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import $ from 'jquery';
import './Custom.css';
import LeaveCardGroup from './LeaveCardGroup';
import AddEditModal from './AddEditModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchLeaves,
  addLeave,
  updateLeave,
  deleteLeave,
  realAllLeaves,
  seenRequestids,
} from '../../slices/leaveSlice';
import { validateLeaveRequest } from '../common/Joi_Validation';
import { socketIO } from '../../App';
import { fetchAllUsersData } from '../../slices/Users';
import { fetchLeadsData } from '../../slices/Lead';
import ViewAll from './components/viewAllLeave';
import LeaveTable from './components/LeaveTable';
import Breadcrumbb from './components/Breadcrumb';

export default function AllLeaveRequest() {
  const dispatch = useDispatch();
  const leaves = useSelector((state) => state.leave.leaves);
  const isAdmin = localStorage.getItem('admin') === 'true';
  const isLead = localStorage.getItem('lead') === 'true';
  const isHR = localStorage.getItem('hr') === 'true';
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedLeaveType, setSelectedLeaveType] = useState('');
  const [selectedLeaveId, setSelectedLeaveId] = useState(null);
  const employeeId = localStorage.getItem('employeeID');
  const [employeeIdd, setEmployeeIdd] = useState(localStorage.getItem('employeeID'));
  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const name = firstName + ' ' + lastName;
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const leads = useSelector((state) => state.leads.leadsData.leads);
  const isNewLeaveRequest = useSelector((state) => state.leave.isNewRequest);
  const unreadLeaves = useSelector((state) => state.leave.unreadLeaves);
  const [fromReadMore, setFromReadMore] = useState(false);
  const [initialFormData, setInitialFormData] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    leavetype: '',
    leavefrom: '',
    leaveto: '',
    noofdays: '',
    statusText: 'Pending',
    reason: '',
    lead: [],
  });
  useEffect(() => {
    // dispatch(newRequest(false));
    let access = false;
    if (
      localStorage.getItem('hr') === 'true' ||
      localStorage.getItem('admin') === 'true' ||
      localStorage.getItem('lead') === 'true'
    ) {
      access = true;
    }
    // console.log('access---', access)
    if (unreadLeaves?.length > 0) {
      dispatch(realAllLeaves({ employeeId, access }));
    }
    // //we need this seenrequestwids because if request is seen by pratik then login in gaurav he cant see badge so we need
    // // to store the ids of admins to ensure leave is seen by this admin not by other admins
    dispatch(seenRequestids());
    dispatch(fetchLeaves(employeeId));
    // dispatch(fetchAllUsersData());
    // dispatch(fetchLeadsData());
  }, [dispatch, employeeId]);

  useEffect(() => {
    if (show) {
      dispatch(fetchAllUsersData());
      dispatch(fetchLeadsData());
    }
  }, [dispatch, show]);

  const handleLeadChange = (selectedOptions) => {
    setSelectedLeads(selectedOptions.label);
    const selectedLeadValues = selectedOptions;
    handleChange({ target: { name: 'lead', value: selectedLeadValues } });
  };

  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption);
    // console.log("selectedOption", selectedOption);
    // console.log(
    //   selectedOption,
    //   ' selectedOptionselectedOptionselectedOptionselectedOptionselectedOption selectedOptionselectedOption',
    // );
    handleChange({ target: { name: 'employeename', value: selectedOption } });
  };

  const handleShow = (data = null) => {
    setErrors({});
    if (data) {
      const initialData = {
        noofdays: data.noofdays,
        leavetype: data.leavetype,
        leavefrom: new Date(data.leavefrom),
        leaveto: new Date(data.leaveto),
        reason: data.reason,
        statusText: data.statusText,
        lead: data.lead,
        employeename: data.employeename,
      };
      setInitialFormData(initialData);
      setFormData(initialData);
      if (data.fromAdmin) {
        setSelectedEmployee(
          data.employeename
            ? { value: data.employeename.value, label: data.employeename.label }
            : { value: data.employeeId, label: data.name },
        );
      } else {
        setSelectedEmployee({ value: data.employeeId, label: data.name });
      }
      setSelectedLeads(
        data.lead ? data.lead.map((lead) => ({ value: lead.value, label: lead.label })) : [],
      );
      setSelectedLeaveId(data.id);
      setSelectedLeaveType(data.leavetype);
    } else {
      const initialData = {
        name: name,
        leavetype: '',
        leavefrom: '',
        leaveto: '',
        reason: '',
        lead: [],
      };
      setInitialFormData(initialData);
      setFormData(initialData);
      setSelectedEmployee(null);
      setSelectedLeads([]);
      setSelectedLeaveId(null);
      setSelectedLeaveType('');
    }
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date, field) => {
    if (date) {
      date = new Date(date);
    }
    setFormData({
      ...formData,
      [field]: date,
    });
  };

  const handleLeaveTypeChange = (event) => {
    const { name, value } = event.target;
    handleChange(event);
    setSelectedLeaveType(value);
  };

  useEffect(() => {
    const calculateNoOfDays = (leavefrom, leaveto, leavetype) => {
      if (leavetype === 'First Half' || leavetype === 'Second Half') {
        return 0.5;
      }
      const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
      const diffDays = Math.round(Math.abs((new Date(leaveto) - new Date(leavefrom)) / oneDay)) + 1;
      return diffDays;
    };

    const noofdays = calculateNoOfDays(formData.leavefrom, formData.leaveto, formData.leavetype);
    setFormData((prevFormData) => ({
      ...prevFormData,
      noofdays: noofdays,
    }));
  }, [formData.leavefrom, formData.leaveto, formData.leavetype]);

  const handleSubmit = async (e) => {
    // console.log("fur fot to ")
    e.preventDefault();
    setIsLoading(true);

    const updatedFormData = {
      ...formData,
      noofdays: formData.noofdays,
    };
    const validationErrors = validateLeaveRequest(updatedFormData);
    console.log('validationErrors', errors);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }

    const newLeaveStart = new Date(formData.leavefrom);
    const newLeaveEnd = formData.leaveto ? new Date(formData.leaveto) : newLeaveStart;
    // Determine if the new leave is for the first or second half of the day
    const newLeaveType = formData.leavetype;
    // Check for conflicts
    const conflictingLeave = leaves.find((leave) => {
      const leaveStart = new Date(leave.leavefrom);
      const leaveEnd = leave.leaveto ? new Date(leave.leaveto) : leaveStart;
      // Determine if the existing leave is for the first or second half of the day
      const existingLeaveType = leave.leavetype;
      // Check if the new leave overlaps with the existing leave
      const isOverlapping = isHR
        ? newLeaveStart <= leaveEnd &&
          newLeaveEnd >= leaveStart &&
          leave.employeename?.label === formData.employeename?.label
        : isLead
          ? newLeaveStart <= leaveEnd &&
            newLeaveEnd >= leaveStart &&
            leave.employeename?.label === formData.employeename?.label
          : newLeaveStart <= leaveEnd && newLeaveEnd >= leaveStart;
      // leave.employeename?.label === formData.employeename?.label;
      // Check for half-day conflicts on the same date
      const isSameDay = leaveStart.toDateString() === newLeaveStart.toDateString();
      const isHalfDayConflict =
        isSameDay &&
        ((existingLeaveType === 'firstHalf' && newLeaveType === 'firstHalf') ||
          (existingLeaveType === 'secondHalf' && newLeaveType === 'secondHalf'));
      // Exclude the current leave being edited from conflict checks
      return (isOverlapping || isHalfDayConflict) && leave.id !== selectedLeaveId;
    });
    // Handle validation error
    if (conflictingLeave) {
      setErrors({ leavetype: 'Conflict' });
      setIsLoading(false);
      return;
    }

    try {
      if (selectedLeaveId) {
        setIsLoading(true);
        await dispatch(updateLeave({ id: selectedLeaveId, updatedLeave: updatedFormData }));
        dispatch(fetchLeaves(employeeId));

        // toast.success('Leave request updated successfully!');
      } else {
        const newFormData = {
          ...updatedFormData,
          employeeId: employeeId,
          requestDate: new Date().toISOString().substr(0, 10),
          fromAdmin: localStorage.getItem('hr') === 'true' ? true : false,
          name: name,
        };
        socketIO.emit('notification');
        await dispatch(addLeave(newFormData));
        dispatch(fetchLeaves(employeeId));

        // toast.success('Leave request added successfully!');
      }
      socketIO.emit('notification');
      setErrors({});
    } catch (error) {
      console.error('Error creating/updating leave request:', error);
    }

    setIsLoading(false);
    handleClose();
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    try {
      await dispatch(deleteLeave(id));
      // toast.success('Leave request deleted successfully!');
    } catch (error) {
      console.error('Error deleting leave request:', error);
    }
    setIsLoading(false);
  };

  const handleStatusChange = async (e, leaveId) => {
    const newStatus = e.target.value;
    try {
      const updatedLeave = {
        statusText: newStatus,
        status: newStatus,
        approvedBy: name,
      };
      await dispatch(updateLeave({ id: leaveId, updatedLeave }));
    } catch (error) {
      console.error('Error updating leave request status:', error);
    }
  };

  //  DataTable.Buttons.jszip(JSZip);
  useEffect(() => {
    if (!$.fn.dataTable.isDataTable('.maindatatable')) {
      $('.maindatatable').DataTable({
        dom: 'flrtip',
        language: {
          search: '',
          searchPlaceholder: 'Search...',
          paginate: {
            previous: '«',
            next: '»',
          },
        },
      });
    }
  }, [leaves?.length]);

  return (
    <>
      <div className='page-wrapper'>
        <div className='page-content'>
          <Breadcrumbb title={'Leave Request'} link={'Leave Request'} />
          {!isAdmin && !isLead && !isHR && localStorage.getItem('hr') === 'false' && (
            <LeaveCardGroup leaveRecords={leaves} />
          )}
          <Container>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <ViewAll
                      titlee={'Leave Request'}
                      options2={leads}
                      setEmployeeId={setEmployeeIdd}
                      apply={'Apply Leave'}
                      setFromReadMore={setFromReadMore}
                      handleShow={handleShow}
                    />
                    {leaves?.length > 0 && (
                      <LeaveTable
                        leaves={leaves}
                        handleDelete={handleDelete}
                        handleStatusChange={handleStatusChange}
                        setFromReadMore={setFromReadMore}
                        handleShow={handleShow}
                        isLoading={isLoading}
                      />
                    )}
                    {leaves?.length === 0 && (
                      <LeaveTable
                        leaves={leaves}
                        handleDelete={handleDelete}
                        handleStatusChange={handleStatusChange}
                        setFromReadMore={setFromReadMore}
                        handleShow={handleShow}
                        isLoading={isLoading}
                      />
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
      <AddEditModal
        show={show}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        handleLeaveTypeChange={handleLeaveTypeChange}
        handleDateChange={handleDateChange}
        formData={formData}
        selectedLeaveType={selectedLeaveType}
        handleChange={handleChange}
        selectedLeaveId={selectedLeaveId}
        errors={errors}
        isLoading={isLoading}
        selectedEmployee={selectedEmployee}
        selectedLeads={selectedLeads}
        handleEmployeeChange={handleEmployeeChange}
        handleLeadChange={handleLeadChange}
        options2={leads}
        fromReadMore={fromReadMore}
        setFormData={setFormData}
        initialFormData={initialFormData}
      />
    </>
  );
}
