import React from 'react';
import { Col, Form } from 'react-bootstrap';

export default function ModalFormBasicEmailFields({ controlId, label, value, onChange, errors }) {
  return (
    <Col lg={4}>
      <Form.Group controlId={controlId} className='mb-3'>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type='email'
          placeholder={`Enter ${label.toLowerCase()}...`}
          name={controlId}
          value={value}
          onChange={onChange}
          required
        />
        {errors && <p className='text-danger'>{label} is not allowed to be empty</p>}
      </Form.Group>
    </Col>
  );
}
