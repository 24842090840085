import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { socketIO } from '../App';
import { seenProjectByAdmin } from '../slices/Project';

export const ProjectSocketClient = () => {
  const isAdminOrLead =
    localStorage.getItem('admin') === 'true' ||
    localStorage.getItem('lead') === 'true' ||
    localStorage.getItem('hr') === 'true';
  const dispatch = useDispatch();

  useEffect(() => {
    socketIO.on('pendingLeaveRequestt', () => {
      if (isAdminOrLead) {
        dispatch(seenProjectByAdmin());
      }
    });
    return () => {
      socketIO.off('pendingLeaveRequestt');
    };
  }, [dispatch]);

  return null;
};
