import React from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';

export default function DeleteModel({
  loadingEmployeeDelete,
  show,
  handleClose,
  id,
  handleDeleteUser,
}) {
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleDeleteUser(id);
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName='modal-lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Employee</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <Row>
              <Col md={12}>
                <p> Are you sure you want to delete this employee&apos;s data</p>
              </Col>
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button
            style={{ padding: '0.3rem 0.7rem', fontSize: '1rem' }}
            variant='primary'
            onClick={handleSubmit}
          >
            {loadingEmployeeDelete ? 'Deleting... ' : 'Delete'}
          </Button>
          <Button
            style={{ padding: '0.3rem 0.7rem', fontSize: '1rem' }}
            variant='secondary'
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
