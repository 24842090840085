import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { fetchAllProjectData, removeProject } from '../../slices/Project';
import trash from '../../images/trash.gif';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function DeleteProjectModal({ show, handleClose, selectedProject }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingDeleteProject, setLoadingDeletingProject] = useState(false);

  const deleteProject = async (project) => {
    try {
      if (!loadingDeleteProject) {
        setLoadingDeletingProject(true);
        await axios.delete(`${process.env.REACT_APP_API_BASE_PATH}/api/projects/${project.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token') || Cookies.get('token')}`,
          },
        });
        dispatch(removeProject(project.id));
        dispatch(fetchAllProjectData());
        toast.info('Deleting Project Successful!', {
          position: 'top-right',
        });
        setLoadingDeletingProject(false);
      }
      handleClose();
    } catch (error) {
      if (!loadingDeleteProject) {
        console.error('Error deleting project:', error);
        toast.error('Deleting Project Caused Error!', {
          position: 'top-right',
        });
        if (error.response.status === 401) {
          console.log(error.response.status, 'error.response.status');
          localStorage.clear();
          Cookies.remove('token');

          navigate('/login');
        }
        setLoadingDeletingProject(false);
      }
    }
  };

  return (
    <Modal centered show={show} onHide={handleClose} dialogClassName='modal-md'>
      <Modal.Body className='text-center' style={{ paddingTop: '0px' }}>
        <img src={trash} alt='' />
        <p>Are you sure?</p>
        <p>Are you certain you want to delete this record?</p>
        <Button
          style={{ padding: '0.3rem 0.7rem', fontSize: '1rem' }}
          variant='primary'
          className='me-3'
          disabled={loadingDeleteProject}
          onClick={() => {
            deleteProject(selectedProject);
          }}
        >
          {loadingDeleteProject ? 'Deleting...' : ' Yes! Delete It'}
        </Button>
        <Button
          style={{ padding: '0.3rem 0.7rem', fontSize: '1rem' }}
          variant='secondary'
          onClick={handleClose}
          className='ms-3'
        >
          Cancel
        </Button>
      </Modal.Body>
    </Modal>
  );
}
