import React from 'react';
import { ToastContainer } from 'react-toastify';
import './App.css';
import Routes from './components/routes/Index';
import MenuState from './context/MenuState';
import 'react-toastify/dist/ReactToastify.css';
import io from 'socket.io-client';
import { SocketClient } from './socket/socketClient';
import { ProjectSocketClient } from './socket/projectSocketClient';
import { TimesheetSocketClient } from './socket/timesheetSocketClient';
import { RemoteSocketClient } from './socket/remoteSocketclient';

// console.log('process.env.REACT_APP_API_BASE_PATH', process.env.REACT_APP_API_BASE_PATH);
export const socketIO = io(process.env.REACT_APP_API_BASE_PATH);

function App() {
  return (
    <div>
      <SocketClient />
      <ProjectSocketClient />
      <TimesheetSocketClient />
      <RemoteSocketClient />
      <MenuState>
        <ToastContainer
          position='top-right'
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
        />
        <Routes />
      </MenuState>
    </div>
  );
}

export default App;
