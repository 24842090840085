import Joi from 'joi';

// const TimesheetSchema = Joi.object({
//     project: Joi.array().items(Joi.string().required().messages({ 'any.required': 'Project is required' })).messages({ 'any.required': 'Project Name is required' }),
//     date: Joi.date().messages({ 'date.base': 'Valid date is required' }),
//     employeeId: Joi.string().required().messages({ 'any.required': 'Employee ID is required' }),
//     details: Joi.string().trim().min(1).required().messages({ 'any.required': 'Details are required' }),
//     projectLead: Joi.array().items(
//       Joi.object({
//         value: Joi.string().required(),
//         label: Joi.string().required(),
//       })
//     ).min(1).required().messages({
//       'array.min': `"Project Lead" must have at least 1 item`,
//       'object.base': `"Project Lead" must be of type object`,
//     }),
//      employee: Joi.string(),
// });

const TimesheetSchema = Joi.object({
  project: Joi.array()
    .items(
      Joi.object({
        value: Joi.string().required(),
        label: Joi.string().required(),
      }),
    )
    .min(1)
    .required()
    .messages({
      'array.min': 'Project   must have at least 1 item',
      'object.base': 'Project must be of type object',
      'string.empty': 'Project is required',
    }),
  date: Joi.date().required().messages({
    'date.base': 'Valid date is required',
    'any.required': 'Date is required',
  }),
  employeeId: Joi.string().required().messages({
    'string.empty': 'Employee ID is required',
  }),
  details: Joi.string().trim().min(1).required().messages({
    'string.empty': 'Details are required',
  }),
  projectLead: Joi.array()
    .items(
      Joi.object({
        value: Joi.number().required(),
        label: Joi.string().required(),
      }),
    )
    .min(1)
    .required()
    .messages({
      'array.min': 'Project Lead must have at least 1 item',
      'object.base': 'Project Lead must be of type object',
      'string.empty': 'Project Lead is required',
    }),
  employee: Joi.string(),
});
export function validateTimesheet(timesheet) {
  const { error } = TimesheetSchema.validate(timesheet, { abortEarly: false });
  if (!error) return {};
  return error.details.reduce((errors, { path, message }) => {
    const [key] = path;
    return { ...errors, [key]: message };
  }, {});
}

const timesheetEditSchema = Joi.object({
  id: Joi.number(),
  project: Joi.array()
    .items(
      Joi.object({
        value: Joi.string().required(),
        label: Joi.string().required(),
      }),
    )
    .min(1)
    .required()
    .messages({
      'array.min': 'Project   must have at least 1 item',
      'object.base': 'Project must be of type object',
      'string.empty': 'Project is required',
    }),
  date: Joi.date().required().messages({
    'date.base': 'Valid date is required',
    'any.required': 'Date is required',
  }),
  details: Joi.string().trim().min(1).required().messages({
    'string.empty': 'Details are required',
  }),
  projectLead: Joi.array()
    .items(
      Joi.object({
        value: Joi.number().required(),
        label: Joi.string().required(),
      }),
    )
    .min(1)
    .required()
    .messages({
      'array.min': '"Project Lead" must have at least 1 item',
      'object.base': '"Project Lead" must be of type object',
    }),
  employee: Joi.string(),
  edittedBy: Joi.string(),
});
export function validateTimesheetEdit(timesheetEdit) {
  const { error } = timesheetEditSchema.validate(timesheetEdit, { abortEarly: false });
  if (!error) return {};
  return error.details.reduce((errors, { path, message }) => {
    const [key] = path;
    return { ...errors, [key]: message };
  }, {});
}

const ProjectSchema = Joi.object({
  projectName: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'any.required': 'Project Name is required' }),
  projectStatus: Joi.object().required().messages({ 'any.required': 'Project Status is required' }),
  projectLead: Joi.array()
    .items(
      Joi.object({
        value: Joi.number().required(),
        label: Joi.string().required(),
      }),
    )
    .min(1)
    .required()
    .messages({
      'array.min': '"Project Lead" must have at least 1 item',
      // 'object.base': '"Project Lead" must be of type object',
    }),
  createdBy: Joi.string().messages({ 'any.required': 'Created By is required' }),
});

export function validateProject(project) {
  const { error } = ProjectSchema.validate(project, { abortEarly: false });
  if (!error) return {};
  return error.details.reduce((errors, { path, message }) => {
    const [key] = path;
    return { ...errors, [key]: message };
  }, {});
}

const ProjectEditSchema = Joi.object({
  projectName: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'any.required': 'Project Name is required' }),
  projectStatus: Joi.string().required().messages({ 'any.required': 'Project Status is required' }),
  projectLead: Joi.array()
    .items(
      Joi.object({
        value: Joi.number().required(),
        label: Joi.string().required(),
      }),
    )
    .min(1)
    .required()
    .messages({
      'array.min': '"Project Lead" must have at least 1 item',
      'object.base': '"Project Lead" must be of type object',
    }),
  edittedBy: Joi.string().messages({ 'any.required': 'Created By is required' }),
});
export function validateProjectEdit(project) {
  const { error } = ProjectEditSchema.validate(project, { abortEarly: false });
  if (!error) return {};
  return error.details.reduce((errors, { path, message }) => {
    const [key] = path;
    return { ...errors, [key]: message };
  }, {});
}

const LeaveRequestSchema = Joi.object({
  requestDate: Joi.date(),
  employeename: Joi.any(),
  // employeename: Joi.object({
  //     value: Joi.string().required(),
  //     label: Joi.string().required(),
  //   }).messages({
  //     'object.base': `"employeename" must be of type object`,
  //     'any.required': `"employeename" is a required field`,
  //   }),
  leavetype: Joi.string().required().messages({
    'any.required': 'Leave Type is required.',
  }),
  leavefrom: Joi.date().required().messages({
    'date.base': 'Leave From date is not allowed to be empty.',
    'any.required': 'Leave From date is required.',
  }),
  leaveto: Joi.when('leavetype', {
    is: Joi.string().valid('First Half', 'Second Half'),
    then: Joi.any(),
    otherwise: Joi.date().min(Joi.ref('leavefrom')).required().messages({
      'date.base': 'Leave To date is not allowed to be empty.',
      'date.min': 'Leave To date cannot be before leave from date.',
      'any.required': 'Leave To date is required.',
    }),
  }),
  reason: Joi.string().trim().min(1).required().messages({
    'any.required': 'Reason is required.',
  }),
  lead: Joi.array()
    .items(
      Joi.object({
        value: Joi.number().required(),
        label: Joi.string().required(),
      }),
    )
    .min(1)
    .required()
    .messages({
      'array.min': '"Lead" must have at least 1 item',
      'object.base': '"Lead" must be of type object',
    }),
  employeeId: Joi.string(),
  // approvedBy: Joi.string(),
  noofdays: Joi.number(),
  fromAdmin: Joi.boolean(),
  name: Joi.string(),
  statusText: Joi.string(),
  // appliedBy: Joi.string(),
  appliedBy: Joi.any(),
});
export function validateLeaveRequest(updatedFormData) {
  const { error } = LeaveRequestSchema.validate(updatedFormData, {
    abortEarly: false,
  });
  if (!error) return {};
  return error.details.reduce((errors, { path, message }) => {
    const [key] = path;
    return { ...errors, [key]: message };
  }, {});
}

const RemoteWorkingSchema = Joi.object({
  remoteStartDate: Joi.date().required().messages({
    'date.base': 'Remote Start date is not allowed to be empty.',
    'any.required': 'Remote Start date is required.',
  }),
  remoteEndDate: Joi.date().min(Joi.ref('remoteStartDate')).required().messages({
    'date.base': 'Remote End date is not allowed to be empty.',
    'any.required': 'Remote End date is required.',
    'date.greater': 'Remote End date must be after remote start date.',
  }),
  requestDate: Joi.date(),
  employeename: Joi.any(),
  // object({
  //   value: Joi.number().required(),
  //   label: Joi.string().required(),
  // }).messages({
  //   'object.base': '"employeename" must be of type object',
  //   'any.required': '"employeename" is a required field',
  // }),

  reason: Joi.string().trim().min(1).required().messages({
    'any.required': 'Reason is required.',
  }),
  lead: Joi.array()
    .items(
      Joi.object({
        value: Joi.number().required(),
        label: Joi.string().required(),
      }),
    )
    .min(1)
    .required()
    .messages({
      'array.min': '"Lead" must have at least 1 item',
      'object.base': '"Lead" must be of type object',
    }),
  employeeId: Joi.string(),
  // approvedBy: Joi.string(),
  noofdays: Joi.number(),
  fromAdmin: Joi.boolean(),
  name: Joi.string(),
  status: Joi.string(),
});
export function validateRemoteLeaveRequest(requestData) {
  const { error } = RemoteWorkingSchema.validate(requestData, {
    abortEarly: false,
  });
  if (!error) return {};
  return error.details.reduce((errors, { path, message }) => {
    const [key] = path;
    return { ...errors, [key]: message };
  }, {});
}

const EmployeeSchema = Joi.object({
  profilephoto: Joi.string().required().messages({ 'any.required': 'Profile Photo is required' }),
  firstName: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'any.required': 'First Name is required' }),
  lastName: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'any.required': 'Last Name is required' }),
  email: Joi.string()
    .trim()
    .min(1)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.email': 'Email must be a valid email',
      'any.required': 'Email is required',
    }),
  password: Joi.string()
    .trim() // Remove leading/trailing spaces
    .min(8) // Minimum length of 8 characters
    .pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'))
    .required()
    .messages({
      'any.required': 'Password is required',
      'string.empty': 'Password cannot be empty',
      'string.pattern.base':
        'Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long.',
    }),
  contactNo: Joi.string()
    .required()
    .replace(/\s+/g, '')
    .pattern(/^[0-9]{10}$/) // Ensure only digits and exactly 10 characters
    .messages({
      'any.required': 'Contact No is required',
      'string.empty': 'Contact No cannot be empty',
      'string.pattern.base': 'Contact No must be exactly 10 digits and must contain only digit',
    }),
  address: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'any.required': 'Address is required' }),
  altContactNo: Joi.string()
    .required()
    .replace(/\s+/g, '')
    .pattern(/^[0-9]{10}$/) // Ensure only digits and exactly 10 characters
    .messages({
      'any.required': ' Alternate Contact No is required',
      'string.empty': ' Alternate Contact No is required',
      'string.pattern.base':
        'Alternate  Contact No must be exactly 10 digits and must contain only digit',
    }),
  employeeSalary: Joi.number()
    .required()
    .messages({ 'any.required': 'Employee Salary is required' }),
  role: Joi.string().required().messages({ 'any.required': 'role is required' }),
  designation: Joi.string().required().messages({ 'any.required': 'Designation is required' }),
  aadharCardNo: Joi.string()
    .replace(/\s+/g, '') // Ensure the Aadhaar number is exactly 12 digits long
    .length(12)
    .pattern(/^[0-9]{12}$/) // Regular expression to match only numbers
    .required()
    .messages({
      'any.required': 'Aadhar Card No is required',
      'string.empty': 'Aadhar Card No  cannot be empty',
      'string.length.base': 'Aadhar Card Number must be exactly 12 digits long',
      'string.pattern.base':
        'Aadhar Card Number must only contain digits and must be exactly 12 digits long',
    }),
  nameOnAadhar: Joi.string()
    .required()
    .messages({ 'any.required': 'Name on Aadhar Card is required' }),
  mobileLinkedToAadhar: Joi.string()
    .required()
    .replace(/\s+/g, '')
    .pattern(/^[0-9]{10}$/) // Ensure only digits and exactly 10 characters
    .messages({
      'any.required': 'Mobile Linked to Aadhar is required',
      'string.empty': 'Mobile Linked to Aadhar is required',

      'string.pattern.base':
        'Mobile Linked to Aadhar must be exactly 10 digits and must contain only digit',
    }),
  panCardNo: Joi.string()
    .pattern(new RegExp('^[A-Z]{5}[0-9]{4}[A-Z]{1}$')) // Regular expression for PAN Card number validation
    .required()
    .messages({
      'any.required': 'PAN Card No is required',
      'string.empty': 'PAN Card No  cannot be empty',
      'string.pattern.base': 'Invalid PAN Card Number. It should be in the format ABCDE1234Z.',
    }),

  panCardName: Joi.string().required().messages({ 'any.required': 'Pan Card Name is required' }),
  panCardMobileNo: Joi.string()
    .required()
    .replace(/\s+/g, '')
    .pattern(/^[0-9]{10}$/) // Ensure only digits and exactly 10 characters
    .messages({
      'any.required': 'Mobile Linked to Pan is required',
      'string.empty': 'Mobile Linked to Pan is required',
      'string.pattern.base':
        'Mobile Linked to Pan must be exactly 10 digits and must contain only digit',
    }),
  familyMember1FullName: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'any.required': 'Family Member 1 Full Name is required' }),
  familyMember1Relation: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'any.required': 'Family Member 1 Relation is required' }),
  familyMember1MobileNo: Joi.string()
    .required()
    .replace(/\s+/g, '')
    .pattern(/^[0-9]{10}$/) // Ensure only digits and exactly 10 characters
    .messages({
      'any.required': ' Family Member 1 No is required',
      'string.empty': ' Family Member 1 No is required',
      'string.pattern.base':
        'Family Member 1 No must be exactly 10 digits and must contain only digit',
    }),
  familyMember2FullName: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'any.required': 'Family Member 2 Full Name is required' }),
  familyMember2Relation: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'any.required': 'Family Member 2 Relation is required' }),
  familyMember2MobileNo: Joi.string()
    .required()
    .replace(/\s+/g, '')
    .pattern(/^[0-9]{10}$/) // Ensure only digits and exactly 10 characters
    .messages({
      'any.required': ' Family Member 2 No is required',
      'string.empty': ' Family Member 2 No is required',
      'string.pattern.base':
        'Family Member 2 No must be exactly 10 digits and must contain only digit',
    }),
  dateOfBirth: Joi.date().required().messages({ 'any.required': 'Date of Birth is required' }),
  employeeStatus: Joi.string()
    .required()
    .messages({ 'any.required': 'Employee Status is required' }),
  joiningDate: Joi.date().required().messages({ 'any.required': 'Joining Date is required' }),
  gender: Joi.string().required().messages({ 'any.required': 'Gender is required' }),
});
export function validateEmployee(employee) {
  const { error } = EmployeeSchema.validate(employee, { abortEarly: false });
  if (!error) return {};
  return error.details.reduce((errors, { path, message }) => {
    const [key] = path;
    return { ...errors, [key]: message };
  }, {});
}

const ProfileSchema = Joi.object({
  firstName: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'any.required': 'First Name is required' }),
  lastName: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'any.required': 'Last Name is required' }),
  email: Joi.string()
    .trim()
    .min(1)
    .email({ tlds: { allow: false } })
    .required()
    .messages({ 'any.required': 'Email is required' }),
  contactNo: Joi.string()
    .required()
    .replace(/\s+/g, '')
    .pattern(/^[0-9]{10}$/) // Ensure only digits and exactly 10 characters
    .messages({
      'any.required': 'Contact No is required',
      'string.empty': 'Contact No is required',
      'string.pattern.base': 'Contact No must be exactly 10 digits and must contain only digit',
    }),
  address: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'any.required': 'Address is required' }),
  altContactNo: Joi.string()
    .required()
    .replace(/\s+/g, '')
    .pattern(/^[0-9]{10}$/) // Ensure only digits and exactly 10 characters
    .messages({
      'any.required': ' Alternate Contact No is required',
      'string.empty': ' Alternate Contact No is required',
      'string.pattern.base':
        'Alternate  Contact No must be exactly 10 digits and must contain only digit',
    }),
  dateOfBirth: Joi.date().messages({ 'date.base': 'Valid date is required' }),
  gender: Joi.required().messages({ 'any.required': 'Gender is required' }),
  familyMember1FullName: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'any.required': 'Family Member 1 Full Name is required' }),
  familyMember1Relation: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'any.required': 'Family Member 1 Relation is required' }),
  familyMember1MobileNo: Joi.string()
    .required()
    .replace(/\s+/g, '')
    .pattern(/^[0-9]{10}$/) // Ensure only digits and exactly 10 characters
    .messages({
      'any.required': ' Family Member 1 No is required',
      'string.empty': ' Family Member 1 No is required',
      'string.pattern.base':
        'Family Member 1 No must be exactly 10 digits and must contain only digit',
    }),
  familyMember2FullName: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'any.required': 'Family Member 2 Full Name is required' }),
  familyMember2Relation: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'any.required': 'Family Member 2 Relation is required' }),
  familyMember2MobileNo: Joi.string()
    .required()
    .replace(/\s+/g, '')
    .pattern(/^[0-9]{10}$/) // Ensure only digits and exactly 10 characters
    .messages({
      'any.required': ' Family Member 2 No is required',
      'string.empty': ' Family Member 2 No is required',

      'string.pattern.base':
        'Family Member 2 No must be exactly 10 digits and must contain only digit',
    }),
});
export function validateProfile(profile) {
  // console.log(profile, " hhhhhhhhhhhhhhhhhhh")
  const { error } = ProfileSchema.validate(profile, { abortEarly: false });
  if (!error) return {};
  return error.details.reduce((errors, { path, message }) => {
    const [key] = path;
    return { ...errors, [key]: message };
  }, {});
}

const LoginSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.email': 'Email must be a valid email',
      'any.required': 'Email is required',
    }),
  password: Joi.string().required().messages({ 'any.required': 'Password is required' }),
  rememberMe: Joi.boolean().messages({
    'any.required': 'Remember Me is required',
  }),
});
export function validateLogin(login) {
  //  console.log("login",login)
  const { error } = LoginSchema.validate(login, { abortEarly: false });
  if (!error) return {};
  return error.details.reduce((errors, { path, message }) => {
    const [key] = path;
    return { ...errors, [key]: message };
  }, {});
}
