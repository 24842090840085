import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  user: { loggedUserInfo: null, loading: false, error: null },
};

const loggedInUser = createSlice({
  name: 'logged',
  initialState,
  reducers: {
    loggedUserDataFetch: (state, action) => {
      state.user.loggedUserInfo = action.payload;
    },
  },
});

export default loggedInUser.reducer;
export const { loggedUserDataFetch } = loggedInUser.actions;
