import React from 'react';
import { Button } from 'react-bootstrap';

export default function AddEditButton({
  data,
  setFromReadMore,
  handleShow,
  handleDelete,
  isLoading,
}) {
  const isAdmin = localStorage.getItem('admin') === 'true';
  const isHR = localStorage.getItem('hr') === 'true';
  const isLead = localStorage.getItem('lead') === 'true';
  const leadId = localStorage.getItem('employeeID');
  const isLeadMatch = isLead && leadId !== data.employeeId;
  return (
    <div className='d-flex'>
      <Button
        variant='soft-primary table-btn me-2'
        style={{ padding: '3px' }}
        size='sm'
        onClick={() => {
          setFromReadMore(false);
          handleShow(data);
        }}
        disabled={
          isLeadMatch || // Disable button if lead is true and lead ID matches
          (!isAdmin &&
            !isHR &&
            localStorage.getItem('hr') === 'false' &&
            (data.status === 'Approved' || data.status === 'Rejected' || isLoading))
        }
      >
        <i className='bi bi-pencil-square'></i>
      </Button>
      <Button
        variant='soft-danger table-btn'
        style={{ padding: '5px' }}
        onClick={() => handleDelete(data.id)}
        disabled={
          isLeadMatch || // Disable button if lead is true and lead ID matches
          (!isAdmin &&
            !isHR &&
            localStorage.getItem('hr') === 'false' &&
            (data.status === 'Approved' || data.status === 'Rejected' || isLoading))
        }
      >
        <i className='bi bi-trash3'></i>
      </Button>
    </div>
  );
}
