import React, { useRef } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';

export default function AddEditModal({
  show,
  handleClose,
  handleSubmit,
  handleLeaveTypeChange,
  handleDateChange,
  formData,
  selectedLeaveType,
  handleChange,
  selectedLeaveId,
  errors,
  isLoading,
  selectedLeads,
  selectedEmployee,
  handleLeadChange,
  handleEmployeeChange,
  options2,
  fromReadMore,
  initialFormData,
}) {
  const hasFormDataChanged = () => {
    return JSON.stringify(formData) !== JSON.stringify(initialFormData);
  };

  const isAdmin = localStorage.getItem('admin') === 'true';

  const users = useSelector((state) => state.users.allUsersData.users);

  let employeeOptions = users?.map((employee) => {
    return { value: employee.id, label: `${employee.firstName} ${employee.lastName}` };
  });

  const dateFromInputRef = useRef(null);
  const dateToInputRef = useRef(null);

  const handleFocusDateFromInput = () => {
    if (dateFromInputRef.current) {
      dateFromInputRef.current.focus();
      dateFromInputRef.current.showPicker();
    }
  };

  const handleFocusDateToInput = () => {
    if (dateToInputRef.current) {
      dateToInputRef.current.focus();
      dateToInputRef.current.showPicker();
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedLeaveId ? 'Update Record' : 'Apply Leave'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {(isAdmin && localStorage.getItem('hr') === 'false') ||
            (!isAdmin && localStorage.getItem('hr') === 'true') ? (
              <Form.Group controlId='employeeName' className='floating-label'>
                <Select
                  name='employeename'
                  id='employeename'
                  options={employeeOptions}
                  value={selectedEmployee}
                  onChange={handleEmployeeChange}
                  isDisabled={fromReadMore}
                  isInvalid={!!errors.employeename}
                />
                <Form.Control.Feedback type='invalid'>{errors.employeename}</Form.Control.Feedback>
              </Form.Group>
            ) : null}

            <Form.Group controlId='formLeaveType' className='floating-label'>
              <Form.Select
                name='leavetype'
                value={formData?.leavetype}
                onChange={handleLeaveTypeChange}
                disabled={fromReadMore}
              >
                <option value=''>Select Leave Type</option>
                <option value='Sick Leave'>Sick Leave (SL)</option>
                <option value='Paid Leave'>Paid Leave</option>
                <option value='First Half'>First Half (FH) (Leave deduction - 0.5)</option>
                <option value='Second Half'>Second Half (SH) (Leave deduction - 0.5)</option>
              </Form.Select>
              {errors.leavetype && (
                <div className='text-danger'>
                  {errors.leavetype === 'Conflict'
                    ? 'Leave request exists for this date range or half-day slot.'
                    : 'Leave Start Date is not allowed to be empty'}
                </div>
              )}
            </Form.Group>

            <div onClick={handleFocusDateFromInput} style={{ cursor: 'pointer' }}>
              <Form.Group controlId='formLeaveFrom' className='floating-label'>
                {/* {console.log("formData.leavefrom", formData.leavefrom)} */}
                <Form.Control
                  type='date'
                  placeholder=' '
                  name='leavefrom'
                  value={formData.leavefrom ? formData.leavefrom.toISOString().substr(0, 10) : ''}
                  onChange={(e) => {
                    // console.log("test", e.target.value);
                    handleDateChange(e.target.value, 'leavefrom');
                  }}
                  disabled={fromReadMore}
                  ref={dateFromInputRef}
                  isInvalid={!!errors.leavefrom}
                />
                <Form.Label>Leave From</Form.Label>
                <Form.Control.Feedback type='invalid'>{errors.leavefrom}</Form.Control.Feedback>
              </Form.Group>
            </div>

            {selectedLeaveType !== 'First Half' && selectedLeaveType !== 'Second Half' && (
              <div onClick={handleFocusDateToInput} style={{ cursor: 'pointer' }}>
                <Form.Group controlId='formLeaveTo' className='floating-label'>
                  <Form.Control
                    type='date'
                    placeholder=' '
                    name='leaveto'
                    min={formData.leavefrom ? formData.leavefrom.toISOString().substr(0, 10) : ''}
                    value={formData.leaveto ? formData.leaveto.toISOString().substr(0, 10) : ''}
                    onChange={(e) => handleDateChange(e.target.value, 'leaveto')}
                    disabled={fromReadMore}
                    ref={dateToInputRef}
                    isInvalid={!!errors.leaveto}
                  />
                  <Form.Label>Leave To</Form.Label>
                  <Form.Control.Feedback type='invalid'>{errors.leaveto}</Form.Control.Feedback>
                </Form.Group>
              </div>
            )}

            <Form.Group controlId='formLead' className='floating-label'>
              <Select
                name='lead'
                id='lead'
                // options={leadOptions}
                options={options2}
                value={selectedLeads}
                onChange={handleLeadChange}
                isDisabled={fromReadMore}
                isMulti
              />
              {errors.lead && (
                <div className='invalid-feedback d-block'>
                  Lead does not contain 1 required value(s)
                </div>
              )}
            </Form.Group>

            <Form.Group controlId='formReason' className='floating-label'>
              <Form.Control
                as='textarea'
                rows={20}
                placeholder=' '
                name='reason'
                value={formData.reason}
                onChange={handleChange}
                disabled={fromReadMore}
                isInvalid={!!errors.reason}
              />
              <Form.Label>Reason</Form.Label>
              <Form.Control.Feedback type='invalid'>
                Reason is not allowed to be empty
              </Form.Control.Feedback>
            </Form.Group>

            {!fromReadMore && (
              <Button
                style={{ padding: '0.3rem 0.7rem', fontSize: '1rem' }}
                variant='primary'
                type='submit'
                disabled={isLoading || !hasFormDataChanged()}
              >
                {isLoading ? 'Submitting' : 'Submit'}
              </Button>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
