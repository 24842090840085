import axios from 'axios';
import moment from 'moment';

// BunnyCDN Storage API endpoint and configuration
const StorageEndpoints = process.env.REACT_APP_BUNNY_STORAGE_ENDPOINT;
const REGION = ''; // If German region, set this to an empty string: ''
const BASE_HOSTNAME = process.env.REACT_APP_BUNNY_BASE_HOSTNAME;
const HOSTNAME = REGION ? `${REGION}.${BASE_HOSTNAME}` : BASE_HOSTNAME;
const STORAGE_ZONE_NAME = process.env.REACT_APP_BUNNY_STORAGE_ZONE_NAME;
const ACCESS_KEY = process.env.REACT_APP_BUNNY_API_KEY;

async function FileUploader(file, currentImageUrl) {
  // const file = event; // Extracting the file from the event

  // console.log("object", currentImageUrl)
  if (!file?.type?.startsWith('image/')) {
    return;
  }

  if (currentImageUrl) {
    const fileName = currentImageUrl.split('/').pop(); // Extract file name from URL

    try {
      const options = {
        method: 'DELETE',
        headers: {
          AccessKey: ACCESS_KEY,
        },
      };
      const response = await fetch(`https://${HOSTNAME}/${STORAGE_ZONE_NAME}/${fileName}`, options);
      if (!response.ok) {
        throw new Error(`Failed to delete image. Status: ${response.status}`);
      }
      // console.log("Image deleted successfully:", response);
    } catch (error) {
      console.error('Error deleting image:', error);
      throw error;
    }
  }
  // If no current image URL, return early

  // Generating a timestamp and constructing the filename for the uploaded image
  const currentTimestamp = moment().format('YYYYMMDD_HHmmss');
  const fileExtension = file.name.split('.').pop();
  const FILENAME_TO_UPLOAD = `image_${currentTimestamp}.${fileExtension}`;

  // Creating a promise to read the file as a data URL
  const reader = new FileReader();
  const fileData = await new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file); // Using readAsArrayBuffer to get the file data
  });

  try {
    // Sending a request to BunnyCDN Storage API to upload the file
    const response = await axios.put(
      `https://${HOSTNAME}/${STORAGE_ZONE_NAME}/${FILENAME_TO_UPLOAD}`,
      fileData,
      {
        headers: {
          AccessKey: ACCESS_KEY,
          'Content-Type': file.type,
        },
      },
    );
  } catch (error) {
    // Handling errors during the file upload process
    console.error('Error uploading file:', error);
    return; // Return early if there is an error
  }

  // Constructing the full image URL using the BunnyCDN Storage endpoint
  const imageUrl = `${StorageEndpoints}${FILENAME_TO_UPLOAD}`;

  // Returning the uploaded image URL
  return imageUrl;
}

// Exporting the FileUploader function as the default export
export default FileUploader;
