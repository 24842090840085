import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { fetchLeaves } from '../../../slices/leaveSlice';
import { useDispatch } from 'react-redux';
import { Button, Card } from 'react-bootstrap';
export default function ViewAll({
  titlee,
  options2,
  setEmployeeId,
  apply,
  handleShow,
  setFromReadMore,
}) {
  const admin = localStorage.getItem('admin');
  const dispatch = useDispatch();
  const isAdmin = localStorage.getItem('admin') === 'true';
  const isHR = localStorage.getItem('hr') === 'true';
  const isLead = localStorage.getItem('lead') === 'true';
  const shouldShowButton = !isAdmin && (isHR || isLead || (!isHR && !isLead));

  const [selectedOption, setSelectedOption] = useState({
    value: localStorage.getItem('employeeID'),
    label: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
  });

  useEffect(() => {
    if (admin === 'true') {
      setEmployeeId(selectedOption.value);
      dispatch(fetchLeaves(selectedOption.value));
    }
  }, [selectedOption?.value, dispatch]);

  return (
    <div className='d-md-flex d-lg-flex justify-content-between align-items-center mb-3'>
      <Card.Title style={{ fontSize: '17px' }} className='mb-lg-0'>
        {titlee}
      </Card.Title>
      <div className='d-md-flex'>
        {admin === 'true' && (
          <div style={{ width: '260px' }}>
            <Select
              name='lead'
              id='lead'
              options={options2}
              value={selectedOption}
              onChange={setSelectedOption}
              className=' ms-2'
              classNamePrefix='select'
            />
          </div>
        )}
        {shouldShowButton && (
          <Button
            variant='soft-primary ms-lg-3'
            style={{ fontSize: '14px', padding: '5px 10px' }}
            onClick={() => {
              setFromReadMore(false);
              handleShow();
            }}
          >
            <i className='bi bi-plus-circle me-2'></i>
            {apply}
          </Button>
        )}
      </div>
    </div>
  );
}
