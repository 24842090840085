import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

const initialState = {
  allProjectData: {
    Project: [],
    status: 'idle',
    loading: true,
    error: null,
    unreadProject: null,
    isNewProjectRequest: null,
  },
};

const employeeId = localStorage.getItem('employeeID');

export const fetchAllProjectData = createAsyncThunk(
  'Project/allProjectData',
  async (arg, { rejectWithValue }) => {
    try {
      // console.log("ffffffffffffffffffffffffffffffffffffff", employeeId)
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_PATH}/api/projects`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token') || Cookies.get('token')}`,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error('API Fetch Error:', error);
      if (error.response && error.response.status === 401) {
        return rejectWithValue('Unauthorized');
      } else {
        throw error;
      }
    }
  },
);

export const realAllProject = createAsyncThunk(
  '/realAllProject',
  async ({ employeeId, access }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_PATH}/api/projects/read/${employeeId}/${access}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token') || Cookies.get('token')}`,
          },
        },
      );
      // console.log("response from proooooo",response)
      // console.log(' to checkot if api is called ', access);
      return response.data.success;
    } catch (error) {
      console.error('API Update Error:', error);
      if (error.response && error.response.status === 401) {
        return rejectWithValue('Unauthorized');
      } else {
        throw error;
      }
    }
  },
);

export const seenProjectByAdmin = createAsyncThunk(
  '/seenProjectByAdmin',
  async (_, { rejectWithValue }) => {
    try {
      // console.log("Calling seenProjectByAdmin API");
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_PATH}/api/projects/seenrequestidsss`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token') || Cookies.get('token')}`,
          },
        },
      );

      // console.log('response from seenProjectByAdmin', response);
      return response.data.data;
    } catch (error) {
      console.error('API Update Error:', error);
      if (error.response && error.response.status === 401) {
        return rejectWithValue('Unauthorized');
      } else {
        throw error;
      }
    }
  },
);

const allProjectDataSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    newProjectRequestt: (state, action) => {
      // console.log("gggggoooooooooooooooooo",action.payload)
      state.allProjectData.isNewProjectRequest = action.payload;
    },
    addProject: (state, action) => {
      state.allProjectData.Project.push(action.payload);
    },
    removeProject: (state, action) => {
      state.allProjectData.Project = state.allProjectData.Project.filter(
        (Project) => Project.id !== action.payload,
      );
    },
    updateProject: (state, action) => {
      const updatedProject = action.payload;
      const index = state.allProjectData.Project.findIndex(
        (Project) => Project.id === updatedProject.id,
      );

      if (index !== -1) {
        state.allProjectData.Project[index] = updatedProject;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProjectData.pending, (state) => {
        state.allProjectData.loading = true;
        state.allProjectData.error = false;
      })
      .addCase(fetchAllProjectData.fulfilled, (state, action) => {
        state.allProjectData.loading = false;
        state.allProjectData.Project = action.payload;
        // state.allProjectData.unreadProject = action?.payload?.find((x) => x.isRead == false);
      })
      .addCase(fetchAllProjectData.rejected, (state) => {
        state.allProjectData.loading = false;
        state.allProjectData.error = true;
        state.allProjectData.Project = [];
      })
      .addCase(seenProjectByAdmin.pending, (state) => {
        state.allProjectData.status = 'loading';
      })
      .addCase(seenProjectByAdmin.fulfilled, (state, action) => {
        // console.log("success",action.payload)
        state.allProjectData.status = 'succeeded';
        state.allProjectData.unreadProject = action?.payload;
        // state.isNewRequest = action?.payload?.find((x) => x.isRead == false);
      })
      .addCase(seenProjectByAdmin.rejected, (state, action) => {
        state.allProjectData.status = 'failed';
        state.allProjectData.error = action.error.message;
      })
      .addCase(realAllProject.fulfilled, (state) => {
        state.allProjectData.isNewProjectRequest = null;
        state.allProjectData.Project.forEach((project) => {
          project.isRead = true;
        });
      });
  },
});

export default allProjectDataSlice.reducer;
export const {
  addProject,
  newProjectRequestt,
  removeProject,
  updateProject,
  markProjectsAsViewed,
} = allProjectDataSlice.actions;
