import React from 'react';
import TopBar from './TopBar';
import LeftSideBar from './LeftSideBar';
// import { useSearchParams } from 'react-router-dom';

export default function Layout() {
  // const[hasNewRequests,setHasNewRequests]=useState(false)
  return (
    <>
      <TopBar />
      <LeftSideBar />
    </>
  );
}
