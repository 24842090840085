import React from 'react';
import { Modal } from 'react-bootstrap';

export default function ModalHeader() {
  return (
    <Modal.Header closeButton>
      <Modal.Title>Add Employee</Modal.Title>
    </Modal.Header>
  );
}
