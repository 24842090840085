import React from 'react';
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap';

export default function Breadcrumbb({ title, link }) {
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div className='d-flex justify-content-between align-items-center page-title-content'>
              <h3 className='page-title' style={{ fontSize: '17px' }}>
                {title}
              </h3>
              <Breadcrumb className='d-none d-md-block'>
                <Breadcrumb.Item href='/' style={{ fontSize: '15px' }}>
                  HRM
                </Breadcrumb.Item>
                <Breadcrumb.Item active style={{ fontSize: '15px' }}>
                  {link}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
