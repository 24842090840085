import React, { useContext, useState, useEffect } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import Logo from '../../images/logo-sm.png';
import MenuContext from '../../context/MenuContext';
import './LeftSideBar.css';
import { useDispatch, useSelector } from 'react-redux';
import { seenRequestids } from '../../slices/leaveSlice';
import { seenRequestidss } from '../../slices/RemoteWorkSlice';
import { seenProjectByAdmin } from '../../slices/Project';
import { realAllTimesheets, seenTimesheetByAdmin } from '../../slices/Timesheet';

export default function LeftSideBar() {
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const context = useContext(MenuContext);
  const { handleclick, iconMenu } = context;
  const employeeId = localStorage.getItem('employeeID');

  //Leave
  const isNewLeaveRequest = useSelector((state) => state.leave.isNewRequest);
  const unreadLeaves = useSelector((state) => state.leave.unreadLeaves);

  //Remote
  const isNewRemoteRequest = useSelector((state) => state.workFromHome.isNewRemoteRequest);
  const unreadRemoteLeavess = useSelector((state) => state.workFromHome.unreadLeavess);

  //Project
  const isNewProjectRequestt = useSelector(
    (state) => state.project.allProjectData.isNewProjectRequest,
  );
  const unreadProject = useSelector((state) => state.project.allProjectData.unreadProject);

  //timesheet
  const isNewtimesheetRequest = useSelector(
    (state) => state.timesheet.allTimesheetData.isNewTimesheet,
  );

  const { unreadTimesheets } = useSelector((state) => state.timesheet.allTimesheetData);

  useEffect(() => {
    dispatch(seenRequestids());
  }, [dispatch]);

  useEffect(() => {
    dispatch(seenRequestidss());
  }, [dispatch]);

  useEffect(() => {
    dispatch(seenProjectByAdmin());
  }, [dispatch]);

  useEffect(() => {
    // let access = false;
    // if (
    //   localStorage.getItem('hr') === 'true' ||
    //   localStorage.getItem('admin') === 'true' ||
    //   localStorage.getItem('lead') === 'true'
    // ) {
    //   access = true;
    // }
    // if (unreadTimesheets?.length > 0) {

    //   dispatch(realAllTimesheets({ employeeId, access }));
    // }

    dispatch(seenTimesheetByAdmin());
  }, [dispatch]);

  const handleToggleSubmenu = (event) => {
    event.preventDefault();
    setSubmenuOpen(!submenuOpen);
  };
  const handleRemoteClick = (event) => {
    event.stopPropagation();
  };
  useEffect(() => {
    if (location.pathname === '/workhome') {
      setSubmenuOpen(true);
    }
  }, [location]);

  return (
    <div className='leftbar' onMouseEnter={iconMenu === false ? handleclick : undefined}>
      <div className='d-flex justify-content-between align-items-center'>
        <a href='/'>
          <div className='leftbar-logo'>
            <a href='/'>
              <img src={Logo} alt='' className='logo-lg' height={48} />
            </a>
            <span className='fs-3 logo-lg' style={{ color: '#0047af' }}>
              HRM
            </span>
          </div>
        </a>
        <div className='text-dark fs-24 ' onClick={handleclick} style={{ marginRight: '20px' }}>
          <i className='bi bi-list close avatar-icon'></i>
        </div>
      </div>
      <SimpleBar className='leftbar-menu-scroll'>
        <div className='menu-list'>
          <h6 className='text-muted left-menu-title text-uppercase fs-13 mb-1'>Main Menu</h6>
          <Navbar>
            <Container className='p-0'>
              <Navbar.Toggle aria-controls='basic-navbar-nav' />
              <Navbar.Collapse id='basic-navbar-nav'>
                <Nav className='nav-pills flex-column custom-leftbar w-100'>
                  <NavLink to='/' className='nav-link'>
                    <i className='bi bi-calendar4-event left-nav-icon'></i>
                    <span className='menu-link-text'>Timesheet</span>
                    {(isNewtimesheetRequest || unreadTimesheets?.length > 0) &&
                      (localStorage.getItem('admin') === 'true' ||
                        localStorage.getItem('lead') === 'true' ||
                        localStorage.getItem('hr') === 'true') && (
                        <span className='notification-badge1'></span>
                      )}
                  </NavLink>

                  <NavLink to='/project' className='nav-link'>
                    <i className='bi bi-files-alt left-nav-icon'></i>
                    <span className='menu-link-text'>Project</span>
                    {(isNewProjectRequestt || unreadProject?.length > 0) &&
                      (localStorage.getItem('admin') === 'true' ||
                        localStorage.getItem('lead') === 'true' ||
                        localStorage.getItem('hr') === 'true') && (
                        <span className='notification-badge1'></span>
                      )}
                  </NavLink>

                  <li className='nav-item'>
                    <NavLink to='/employee' className='nav-link'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                          <i className='bi bi-people left-nav-icon'></i>
                          <span className='menu-link-text ml-2'>Employee</span>
                          {(isNewRemoteRequest || unreadRemoteLeavess?.length > 0) &&
                            (localStorage.getItem('admin') === 'true' ||
                              localStorage.getItem('lead') === 'true' ||
                              localStorage.getItem('hr') === 'true') && (
                              <span className='notification-badge'></span>
                            )}
                        </div>
                        <i
                          className={`${
                            iconMenu
                              ? `bi ${
                                  submenuOpen ? 'bi-chevron-up' : 'bi-chevron-down'
                                } toggle-submenu`
                              : 'hidden'
                          }`}
                          onClick={handleToggleSubmenu}
                        ></i>
                      </div>
                    </NavLink>
                    {submenuOpen && (
                      <li onClick={(e) => e.stopPropagation()} style={{ marginLeft: '19px' }}>
                        <NavLink to='/workhome' className='nav-link' onClick={handleRemoteClick}>
                          <span className='menu-link-text'>Remote</span>
                          {(isNewRemoteRequest || unreadRemoteLeavess?.length > 0) &&
                            (localStorage.getItem('admin') === 'true' ||
                              localStorage.getItem('lead') === 'true' ||
                              localStorage.getItem('hr') === 'true') && (
                              <span className='notification-badge1'></span>
                            )}
                        </NavLink>
                      </li>
                    )}
                  </li>

                  <li className='nav-item'>
                    <NavLink to='/leave' className='nav-link'>
                      <i className='bi bi-box-arrow-up-right left-nav-icon'></i>
                      <span className='menu-link-text'>Leave</span>
                      {(isNewLeaveRequest || unreadLeaves?.length > 0) &&
                        (localStorage.getItem('admin') === 'true' ||
                          localStorage.getItem('lead') === 'true' ||
                          localStorage.getItem('hr') === 'true') && (
                          <span className='notification-badge1'></span>
                        )}
                    </NavLink>
                  </li>

                  <NavLink to='/employee_profile' className='nav-link'>
                    <i className='bi bi-clipboard2-check left-nav-icon'></i>
                    <span className='menu-link-text'>Profile</span>
                  </NavLink>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </SimpleBar>
    </div>
  );
}
