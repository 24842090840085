import React, { useState } from 'react';
import MenuContext from './MenuContext';

const MenuState = (props) => {
  const [iconMenu, setIconMenu] = useState(true);

  const handleclick = () => {
    setIconMenu(!iconMenu);
  };

  // console.log(iconMenu, "iconMenu sddddddddddddddddddddddddd " )

  return (
    <MenuContext.Provider value={{ iconMenu, handleclick }}>{props.children}</MenuContext.Provider>
  );
};

export default MenuState;
