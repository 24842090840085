import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
const initialState = {
  allTimesheetData: {
    Timesheet: [],
    loading: true,
    error: null,
    unreadTimesheets: null,
    isNewTimesheet: null,
    totalPages: 0,
    currentPage: 1,
    status: 'idle',
  },
};

// const employeeIdFromRedux = useSelector((state) => state.logged.user.loggedUserInfo);
// const employeeId = localStorage.getItem('employeeID') || employeeIdFromRedux;

// const employeeId = localStorage.getItem('employeeID');

export const fetchAllTimesheetData = createAsyncThunk(
  'timesheet/allTimesheetData',
  async ({ employeeId, page = 1, limit = 10 }, { rejectWithValue }) => {
    // console.log(employeeId, ' dfd');
    const token = localStorage.getItem('token') || Cookies.get('token');
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_PATH}/api/timesheets?employee=${employeeId}&page=${page}&limit=${limit}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      // console.log(response.data.data, "response.data.data")
      return response.data.data;
    } catch (error) {
      if (error.response.status === 401) {
        console.log(error.response.status, 'error.response.status');
        return rejectWithValue('Unauthorized');
      } else {
        throw error;
      }
    }
  },
);

export const realAllTimesheets = createAsyncThunk(
  '/realAllTimesheets',
  async ({ employeeId, access }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_PATH}/api/timesheets/read/${employeeId}/${access}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token') || Cookies.get('token')}`,
          },
        },
      );
      // console.log("response from proooooo",response)
      return response.data.success;
    } catch (error) {
      console.error('API Update Error:', error);
      if (error.response && error.response.status === 401) {
        return rejectWithValue('Unauthorized');
      } else {
        throw error;
      }
    }
  },
);

export const seenTimesheetByAdmin = createAsyncThunk(
  '/seenTimesheetByAdmin',
  async (args, { rejectWithValue }) => {
    try {
      // console.log("Calling seenTimesheetByAdmin API");
      const employeeId = localStorage.getItem('employeeID');
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_PATH}/api/timesheets/seentimesheets/${employeeId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token') || Cookies.get('token')}`,
          },
        },
      );
      // console.log('Response from seenTimesheetByAdmin', response.data.data);
      return response.data.data;
    } catch (error) {
      console.error('API Update Error:', error);
      if (error.response && error.response.status === 401) {
        return rejectWithValue('Unauthorized');
      } else {
        throw error;
      }
    }
  },
);

const allTimesheetDataSlice = createSlice({
  name: 'timesheet',
  initialState,
  reducers: {
    // markTimesheetssAsViewed: (state) => {
    //   state.allTimesheetData.unreadTimesheets = null;
    // },
    newTimesheetRequestt: (state, action) => {
      // console.log('tttttoooooooooooooooooo', action.payload);
      state.allTimesheetData.isNewTimesheet = action.payload;
    },
    addTimesheet: (state, action) => {
      state.allTimesheetData.Timesheet.push(action.payload);
    },
    removeTimesheet: (state, action) => {
      state.allTimesheetData.Timesheet = state.allTimesheetData.Timesheet.filter(
        (Timesheet) => Timesheet.id !== action.payload,
      );
    },
    updateTimesheet: (state, action) => {
      const updatedTimesheet = action.payload;
      // console.log(updatedTimesheet, "updatedTimesheet");
      const index = state.allTimesheetData.Timesheet.findIndex(
        (Timesheet) => Timesheet.id === updatedTimesheet.id,
      );
      if (index !== -1) {
        state.allTimesheetData.Timesheet[index] = updatedTimesheet;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTimesheetData.pending, (state) => {
        state.allTimesheetData.loading = true;
        state.allTimesheetData.error = false;
      })
      .addCase(fetchAllTimesheetData.fulfilled, (state, action) => {
        state.allTimesheetData.loading = false;
        state.allTimesheetData.Timesheet = action.payload;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
        // state.allTimesheetData.unreadTimesheets = action?.payload?.find((x) => x.isRead == false);
      })
      .addCase(fetchAllTimesheetData.rejected, (state) => {
        state.allTimesheetData.loading = false;
        state.allTimesheetData.error = true;
        state.allTimesheetData.Timesheet = [];
      })
      .addCase(seenTimesheetByAdmin.pending, (state) => {
        state.allTimesheetData.status = 'loading';
      })
      .addCase(seenTimesheetByAdmin.fulfilled, (state, action) => {
        // console.log("timesheetaction.payload",action.payload)
        state.allTimesheetData.status = 'succeeded';
        // console.log('actionnnn', action.payload);
        state.allTimesheetData.unreadTimesheets = action?.payload;
        // state.isNewRequest = action?.payload?.find((x) => x.isRead == false);
      })
      .addCase(seenTimesheetByAdmin.rejected, (state, action) => {
        state.allTimesheetData.status = 'failed';
        state.allTimesheetData.error = action.error.message;
      })
      .addCase(realAllTimesheets.fulfilled, (state) => {
        state.allTimesheetData.isNewTimesheet = null;
        state.allTimesheetData.Timesheet.forEach((timesheet) => {
          timesheet.isRead = true;
        });
      });
  },
});
export default allTimesheetDataSlice.reducer;
export const {
  addTimesheet,
  removeTimesheet,
  newTimesheetRequestt,
  updateTimesheet,
  markTimesheetssAsViewed,
} = allTimesheetDataSlice.actions;
