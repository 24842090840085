import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../components/common/Service';
import { toast } from 'react-toastify';

export const fetchLeaves = createAsyncThunk('leave/fetchLeaves', async (employeeId) => {
  const response = await axiosInstance.get(`/api/leave?employee=${employeeId}`);
  // console.log("filter data",response.data.data)
  return response.data.data;
});

export const seenRequestids = createAsyncThunk('/leave/seenRequestids', async () => {
  const response = await axiosInstance.get('/api/leave/seenrequestids');
  // console.log("filter data2222",response.data.data)
  return response.data.data;
});
export const addLeave = createAsyncThunk('leave/addLeave', async (newLeave) => {
  const response = await axiosInstance.post('/api/leave', newLeave);
  toast.info('Leave request added successfully!');
  return response.data.leaveRequest;
});
export const updateLeave = createAsyncThunk('leave/updateLeave', async ({ id, updatedLeave }) => {
  const response = await axiosInstance.put(`/api/leave/${id}`, updatedLeave);
  toast.info('Leave request updated successfully!');
  return response.data;
});
export const deleteLeave = createAsyncThunk('leave/deleteLeave', async (id) => {
  await axiosInstance.delete(`/api/leave/${id}`);
  toast.info('Leave request deleted successfully!');
  return id;
});

export const realAllLeaves = createAsyncThunk('leave/realAllLeaves', async (employeeId) => {
  // console.log("access", access);
  const response = await axiosInstance.put(
    `/api/leave/read/${employeeId.employeeId}/${employeeId.access}`,
  );
  // console.log("responsee",response)
  return response.data.success;
});
const leaveSlice = createSlice({
  name: 'leave',
  initialState: {
    leaves: [],
    unreadLeaves: null,
    status: 'idle',
    error: null,
    // isNewRequest:false
    isNewRequest: null,
  },
  reducers: {
    // markLeavesAsViewed: (state, action) => {
    //   state.unreadLeaves = null;
    // },
    newRequest: (state, action) => {
      state.isNewRequest = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeaves.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLeaves.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.leaves = action.payload;
        // state.unreadLeaves = action?.payload?.find((x) => x.isRead == false);
      })
      .addCase(fetchLeaves.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.leaves = [];
      })
      .addCase(seenRequestids.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(seenRequestids.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.unreadLeaves = action?.payload;
        // state.isNewRequest = action?.payload?.find((x) => x.isRead == false);
      })
      .addCase(seenRequestids.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addLeave.fulfilled, (state, action) => {
        state.leaves.push(action.payload);
      })
      .addCase(updateLeave.fulfilled, (state, action) => {
        const index = state.leaves.findIndex((leave) => leave.id === action.payload.id);
        state.leaves[index] = action.payload;
      })
      .addCase(deleteLeave.fulfilled, (state, action) => {
        state.leaves = state.leaves.filter((leave) => leave.id !== action.payload);
      })
      .addCase(realAllLeaves.fulfilled, (state) => {
        state.isNewRequest = null;
        state?.leaves?.forEach((leave) => {
          leave.isRead = true;
        });
      });
  },
});
export const { markLeavesAsViewed, newRequest } = leaveSlice.actions;
export default leaveSlice.reducer;

//if we fetch from api then we add those things in reducer
// if we just want to change the state then that things will  be include in reducer
