import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { socketIO } from '../App';
import { seenTimesheetByAdmin } from '../slices/Timesheet';

export const TimesheetSocketClient = () => {
  const isAdminOrLead =
    localStorage.getItem('admin') === 'true' ||
    localStorage.getItem('hr') === 'true' ||
    localStorage.getItem('lead') === 'true';
  const dispatch = useDispatch();

  // console.log('isAdminOrLead')
  useEffect(() => {
    // console.log('isAdminOrLead2d',isAdminOrLead)

    socketIO.on('pendingtimesheetRequestt', () => {
      // console.log('isAdminOrLeadggggggggggg', isAdminOrLead);
      if (isAdminOrLead) {
        dispatch(seenTimesheetByAdmin());
      }
    });
    return () => {
      socketIO.off('pendingtimesheetRequestt');
    };
  }, [dispatch]);

  return null;
};
