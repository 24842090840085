import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import FileUploader from '../../common/bunny';

import Cookies from 'js-cookie';
import { loggedUserDetails, updateUserProfile } from '../../../slices/userSlice';
import { validateProfile } from '../../common/Joi_Validation';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import user from '../../../images/user1.png';

const EditForm = ({ setEditForm }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const { userInfo } = useSelector((state) => state.user.userDetails);
  const data = userInfo;
  const navigate = useNavigate();
  const [loadingEditForm, setLoadingEditForm] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(user);
  const [selectedFile, setSelectedFile] = useState('');
  const [initialProfilePhoto, setInitialProfilePhoto] = useState(user);

  const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  };

  const dateInputRef = useRef(null);

  const handleFocusDateInput = () => {
    if (dateInputRef.current) {
      dateInputRef.current.focus();
      dateInputRef.current.showPicker();
    }
  };

  useEffect(() => {
    if (data && data.profilephoto) {
      setSelectedImageUrl(data.profilephoto);
      setInitialProfilePhoto(data.profilephoto);
    } else {
      setSelectedImageUrl(user);
      setInitialProfilePhoto(user);
    }
  }, [data]);

  // console.log(data.profilephoto, " to seeeeeeeeeeeeee ")

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    // console.log("file to send  ", file);
    if (file) {
      setSelectedFile(file);
      setSelectedImageUrl(URL.createObjectURL(file));
    }
  };

  const openFileInput = () => {
    document.getElementById('profilephoto').click();
  };

  const initialData = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    contactNo: data.contactNo,
    altContactNo: data.altContactNo,
    address: data.address,
    dateOfBirth: data.dateOfBirth,
    gender: data.gender,
    familyMember1FullName: data.familyMember1FullName,
    familyMember1Relation: data.familyMember1Relation,
    familyMember1MobileNo: data.familyMember1MobileNo,
    familyMember2FullName: data.familyMember2FullName,
    familyMember2Relation: data.familyMember2Relation,
    familyMember2MobileNo: data.familyMember2MobileNo,
  };

  const [inputData, setInputData] = useState(initialData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData({
      ...inputData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateErrors = validateProfile(inputData);

    if (Object.keys(validateErrors).length > 0) {
      setErrors(validateErrors);
      return;
    }

    try {
      setLoadingEditForm(true);

      let profilephoto;
      if (selectedFile) {
        profilephoto = await FileUploader(selectedFile, data.profilephoto);
        // console.log("profilephoto", profilephoto , data.profilephoto);
        setInputData((prev) => ({
          ...prev,
          profilephoto,
        }));
      }

      const updatedInputData = {
        ...inputData,
        profilephoto,
      };

      await axios.patch(
        `${process.env.REACT_APP_API_BASE_PATH}/api/users/${localStorage.getItem('employeeID')}`,
        updatedInputData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token') || Cookies.get('token')}`,
          },
        },
      );

      dispatch(updateUserProfile(updatedInputData));
      dispatch(loggedUserDetails(localStorage.getItem('employeeID')));
      toast.info('Edited Profile Successfully!', {
        position: 'top-right',
      });
      setEditForm(false);
      setLoadingEditForm(false);
    } catch (error) {
      console.log(error);
      setLoadingEditForm(false);
      if (error.response && error.response.status === 401) {
        console.log(error.response.status, 'error.response.status');
        localStorage.clear();
        Cookies.remove('token');
        navigate('/login');
      }
    }
  };

  const isFormChanged = () => {
    const initialDataString = JSON.stringify(initialData);
    const inputDataString = JSON.stringify(inputData);
    const profilePhotoChanged = initialProfilePhoto !== selectedImageUrl;
    return initialDataString !== inputDataString || profilePhotoChanged;
  };

  const handleClose = () => {
    setEditForm(false);
    setErrors({});
  };

  return (
    <Container>
      <Card>
        <Card.Body>
          <Form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Row>
              <Col lg={12}>
                <div className='d-flex align-items-center justify-content-center'>
                  <div className='position-relative mb-5'>
                    <img
                      src={selectedImageUrl}
                      className='rounded-circle img-thumbnail bg-white avatar-img-xxl'
                      alt='default'
                    />
                    <div
                      style={{ marginBottom: '-14px' }}
                      className='add-btn rounded-circle avatar avatar-xs bg-primary text-white'
                    >
                      <i className='bi bi-plus' onClick={openFileInput}></i>
                    </div>

                    <input
                      type='file'
                      id='profilephoto'
                      accept='image/*'
                      style={{ display: 'none' }}
                      onChange={handlePhotoUpload}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group controlId='EmployeeFirstName' className='mb-3'>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter First name...'
                    name='firstName'
                    value={inputData.firstName}
                    onChange={handleChange}
                  />
                  {errors.firstName && (
                    <p className='text-danger'>First Name is not allowed to be empty</p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId='EmployeeLastName' className='mb-3'>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Last name...'
                    name='lastName'
                    value={inputData.lastName}
                    onChange={handleChange}
                  />
                  {errors.lastName && (
                    <p className='text-danger'>Last Name is not allowed to be empty</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6}>
                <Form.Group controlId='EmployeeAddress' className='mb-3'>
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter address...'
                    name='address'
                    value={inputData.address}
                    onChange={handleChange}
                  />
                  {errors.address && (
                    <p className='text-danger'>Address is not allowed to be empty</p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6} xl={3} md={6}>
                <div onClick={handleFocusDateInput} style={{ cursor: 'pointer' }}>
                  <Form.Group controlId='EmployeeDOB' className='mb-3'>
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      type='date'
                      name='dateOfBirth'
                      value={formatDate(inputData.dateOfBirth)}
                      onChange={handleChange}
                      ref={dateInputRef}
                    />
                    {errors.dateOfBirth && (
                      <p className='text-danger'>Date of Birth is not allowed to be empty</p>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col lg={6} xl={3} md={6}>
                <Form.Group controlId='EmployeeGender' className='mb-3'>
                  <Form.Label>Gender</Form.Label>
                  <Form.Control
                    as='select'
                    name='gender'
                    value={inputData.gender}
                    onChange={handleChange}
                  >
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                    <option value='Other'>Other</option>
                  </Form.Control>
                  {errors.gender && (
                    <p className='text-danger'>Gender is not allowed to be empty</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6} xl={4} md={6}>
                <Form.Group controlId='EmployeeEmail' className='mb-3'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='Enter email...'
                    name='email'
                    required
                    value={inputData.email}
                    onChange={handleChange}
                  />
                  {errors.email && <p className='text-danger'>Email is not allowed to be empty</p>}
                </Form.Group>
              </Col>
              <Col lg={6} xl={4}>
                <Form.Group controlId='EmployeeContactNo' className='mb-3'>
                  <Form.Label>Contact No</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Contact No ....'
                    name='contactNo'
                    value={inputData.contactNo}
                    onChange={handleChange}
                  />
                  {errors.contactNo && <p className='text-danger'>{errors.contactNo}</p>}
                </Form.Group>
              </Col>
              <Col lg={6} xl={4}>
                <Form.Group controlId='EmployeeAltContactNo' className='mb-3'>
                  <Form.Label>Alternate Contact No</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Alternate No ...'
                    name='altContactNo'
                    value={inputData.altContactNo}
                    onChange={handleChange}
                  />
                  {errors.altContactNo && <p className='text-danger'> {errors.altContactNo}</p>}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg={6} xl={4}>
                <Form.Group controlId='FamilyMember1FullName' className='mb-3'>
                  <Form.Label>Family Member 1 Full Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter name...'
                    name='familyMember1FullName'
                    value={inputData.familyMember1FullName}
                    onChange={handleChange}
                  />
                  {errors.familyMember1FullName && (
                    <p className='text-danger'>
                      Family Member 1 Full Name is not allowed to be empty
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6} xl={4}>
                <Form.Group controlId='FamilyMember1Relation' className='mb-3'>
                  <Form.Label>Family Member 1 Relation</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter relation...'
                    name='familyMember1Relation'
                    value={inputData.familyMember1Relation}
                    onChange={handleChange}
                  />
                  {errors.familyMember1Relation && (
                    <p className='text-danger'>
                      Family Member 1 Relation is not allowed to be empty
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6} xl={4}>
                <Form.Group controlId='FamilyMember1MobileNo' className='mb-3'>
                  <Form.Label>Family Member 1 Mobile No.</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter mobile no...'
                    name='familyMember1MobileNo'
                    value={inputData.familyMember1MobileNo}
                    onChange={handleChange}
                  />
                  {errors.familyMember1MobileNo && (
                    <p className='text-danger'>{errors.familyMember1MobileNo}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6} xl={4}>
                <Form.Group controlId='FamilyMember2FullName' className='mb-3'>
                  <Form.Label>Family Member 2 Full Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter name...'
                    name='familyMember2FullName'
                    value={inputData.familyMember2FullName}
                    onChange={handleChange}
                  />
                  {errors.familyMember2FullName && (
                    <p className='text-danger'>
                      Family Member 2 Full Name is not allowed to be empty
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6} xl={4}>
                <Form.Group controlId='FamilyMember2Relation' className='mb-3'>
                  <Form.Label>Family Member 2 Relation</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter relation...'
                    name='familyMember2Relation'
                    value={inputData.familyMember2Relation}
                    onChange={handleChange}
                  />
                  {errors.familyMember2Relation && (
                    <p className='text-danger'>
                      Family Member 2 Relation is not allowed to be empty
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6} xl={4}>
                <Form.Group controlId='FamilyMember2MobileNo' className='mb-3'>
                  <Form.Label>Family Member 2 Mobile No.</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter mobile no...'
                    name='familyMember2MobileNo'
                    value={inputData.familyMember2MobileNo}
                    onChange={handleChange}
                  />
                  {errors.familyMember2MobileNo && (
                    <p className='text-danger'>{errors.familyMember2MobileNo}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Button
              style={{ padding: '0.3rem 0.7rem', fontSize: '1rem' }}
              variant='primary'
              type='submit'
              disabled={loadingEditForm || !isFormChanged()}
              className='float-end'
            >
              {loadingEditForm ? 'Editing...' : 'Save'}
            </Button>
            <Button
              style={{ padding: '0.3rem 0.7rem', fontSize: '1rem' }}
              variant='secondary'
              onClick={handleClose}
              className='float-end me-3'
            >
              Cancel
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EditForm;
