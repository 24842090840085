import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import Loader from '../../common/Loader';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loggedUserDetails } from '../../../slices/userSlice';
import EditForm from './EditForm';
import BasicInfoComponent from './employeeComponent/BasicInfoComponent';
import PersonalInfoComponent from './employeeComponent/PersonalInfoComponent';
import FamilyInfoComponent from './employeeComponent/FamilyInfoComponent';
import WorkInfoComponent from './employeeComponent/WorkInfoComponent';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function EmployeeProfile() {
  const [editForm, setEditForm] = useState(false);
  const employeeId = localStorage.getItem('employeeID');
  const dispatch = useDispatch();
  const { loading, userInfo } = useSelector((state) => state.user.userDetails);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        // console.log("sdd");

        const resultAction = await dispatch(loggedUserDetails(employeeId));

        const user = resultAction.payload;
        if (user === 'Unauthorized' || !localStorage.getItem('employeeID')) {
          navigate('/login');
          localStorage.clear();
          Cookies.remove('token');
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserDetails();
  }, [dispatch]);

  // console.log(userInfo, " ssssssssssss ");
  return (
    <>
      <div className='page-wrapper'>
        <div className='page-content'>
          <PageTitle
            breadcrumbLast='Employee Profile'
            editForm={editForm}
            setEditForm={setEditForm}
          />

          {loading ? (
            <Loader />
          ) : (
            <Container>
              {editForm ? (
                <EditForm setEditForm={setEditForm} />
              ) : (
                <>
                  <BasicInfoComponent userInfo={userInfo} setEditForm={setEditForm} />
                  <PersonalInfoComponent userInfo={userInfo} />
                  <FamilyInfoComponent userInfo={userInfo} />
                  <WorkInfoComponent userInfo={userInfo} />
                </>
              )}
            </Container>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
}
