import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Footer from '../layout/Footer';
import JSZip from 'jszip';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import $ from 'jquery';
import DataTable from 'datatables.net-dt';
import WorkHomeModal from './WorkHomeModal';
import { toast } from 'react-toastify';
import { validateRemoteLeaveRequest } from '../common/Joi_Validation';
import {
  addWorkFromHome,
  deleteWorkFromHome,
  fetchWorkFromHome,
  updateWorkFromHome,
  realAllLeavess,
  seenRequestidss,
} from '../../slices/RemoteWorkSlice';
import { useDispatch, useSelector } from 'react-redux';
import { socketIO } from '../../App';
import { fetchLeadsData } from '../../slices/Lead';
import { fetchAllUsersData } from '../../slices/Users';
import ViewAllRemote from './components/viewAllRemote';
import Breadcrumbb from './components/Breadcrumb';
import RemoteTable from './components/RemoteTable';

export default function WorkFromHome() {
  // const isAdmin = localStorage.getItem("admin") === "true";
  const [showModal, setShowModal] = useState(false);
  const [firstName] = useState(localStorage.getItem('firstName'));
  const [lastName] = useState(localStorage.getItem('lastName'));
  const name = firstName + ' ' + lastName;
  const [selectedLeaveId, setSelectedLeaveId] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const employeeId = localStorage.getItem('employeeID');
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const leads = useSelector((state) => state.leads.leadsData.leads);
  const dispatch = useDispatch();
  const dataa = useSelector((state) => state.workFromHome.data);
  const [fromReadMore, setFromReadMore] = useState(false);
  const [initialFormData, setInitialFormData] = useState(null);
  const isNewRemoteRequest = useSelector((state) => state.workFromHome.isNewRemoteRequest);
  const unreadRemoteLeavess = useSelector((state) => state.workFromHome.unreadLeavess);
  const [employeeIdd, setEmployeeIdd] = useState(localStorage.getItem('employeeID'));
  const [formData, setFormData] = useState({
    name: '',
    remoteStartDate: '',
    remoteEndDate: '',
    reason: '',
    noofdays: '',
    requestDate: new Date(),
    lead: [],
    leavetype: '',
    status: 'pending',
  });

  useEffect(() => {
    // dispatch(newRemoteRequest(false))
    let access = false;
    if (
      localStorage.getItem('hr') === 'true' ||
      localStorage.getItem('admin') === 'true' ||
      localStorage.getItem('lead') === 'true'
    ) {
      access = true;
    }
    if (unreadRemoteLeavess?.length > 0) {
      dispatch(realAllLeavess({ employeeId, access }));
    }
    dispatch(seenRequestidss());
    dispatch(fetchWorkFromHome(employeeId));
    // dispatch(fetchAllUsersData());
    // dispatch(fetchLeadsData());
  }, [dispatch, employeeId]);

  useEffect(() => {
    if (showModal) {
      dispatch(fetchAllUsersData());
      dispatch(fetchLeadsData());
    }
  }, [dispatch, showModal]);

  const handleLeadChange = (selectedOptions) => {
    setSelectedLeads(selectedOptions.label);
    const selectedLeadValues = selectedOptions;
    handleChange({ target: { name: 'lead', value: selectedLeadValues } });
  };

  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption);
    handleChange({
      target: { name: 'employeename', value: selectedOption },
    });
  };

  useEffect(() => {
    const calculateDays = () => {
      const start = new Date(formData.remoteStartDate);
      const end = new Date(formData.remoteEndDate);
      if (start && end && start <= end) {
        const timeDiff = end - start;
        const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1;
        setFormData((prevFormData) => ({
          ...prevFormData,
          noofdays: dayDiff,
        }));
      }
    };

    if (formData?.remoteStartDate && formData?.remoteEndDate) {
      calculateDays();
    }
  }, [formData?.remoteStartDate, formData?.remoteEndDate]);

  const handleShow = (data = null) => {
    setErrors({});
    if (data) {
      const initialData = {
        noofdays: data.noofdays,
        reason: data.reason,
        remoteStartDate: new Date(data?.remoteStartDate),
        remoteEndDate: new Date(data?.remoteEndDate),
        lead: data?.lead,
        employeename: data.employeename,
      };
      setInitialFormData(initialData);
      setFormData(initialData);

      if (data.fromAdmin) {
        setSelectedEmployee(
          data.employeename
            ? { value: data.employeename.value, label: data.employeename.label }
            : { value: data.employeeId, label: data.name },
        );
      } else {
        setSelectedEmployee({ value: data.employeeId, label: data.name });
      }
      setSelectedLeads(
        data.lead ? data.lead.map((lead) => ({ value: lead.value, label: lead.label })) : [],
      );
      setSelectedLeaveId(data.id);
    } else {
      const initialData = {
        name: name,
        remoteStartDate: '',
        remoteEndDate: '',
        reason: '',
        lead: [],
        // noofdays:""
      };
      setInitialFormData(initialData);
      setFormData(initialData);
      setSelectedEmployee(null);
      setSelectedLeads([]);
      setSelectedLeaveId(null);
    }
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const updatedFormData = {
      ...formData,
      noofdays: formData.noofdays,
    };

    const validationErrors = validateRemoteLeaveRequest(formData);

    // console.log(errors, '===> the error', formData.employeename);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      setIsLoading(false);
      return;
    }

    const newRemoteStart = new Date(formData.remoteStartDate);
    const newRemoteEnd = formData.remoteEndDate ? new Date(formData.remoteEndDate) : newRemoteStart;
    // Check for conflicts
    const conflictingLeave = dataa.find((data) => {
      const RemoteStart = new Date(data.remoteStartDate);
      const RemoteEnd = data.remoteEndDate ? new Date(data.remoteEndDate) : RemoteStart;
      // Check if the new remote request overlaps with the existing one
      const isOverlapping =
        newRemoteStart <= RemoteEnd &&
        newRemoteEnd >= RemoteStart &&
        data?.employeename?.label === formData?.employeename?.label;
      // Exclude the current remote request being edited from conflict checks
      return isOverlapping && data.id !== selectedLeaveId;
    });
    if (conflictingLeave) {
      setErrors({ remoteStartDate: 'Conflict' });
      setIsLoading(false);
      return;
    }

    if (selectedLeaveId) {
      await dispatch(updateWorkFromHome({ id: selectedLeaveId, data: updatedFormData }));
      dispatch(fetchWorkFromHome(employeeId));
      toast.info('Record updated successfully!');
    } else {
      const newFormData = {
        ...updatedFormData,
        requestDate: new Date().toISOString().substr(0, 10),
        status: formData.status || 'Pending',
        fromAdmin: localStorage.getItem('hr') === 'true' ? true : false,
        employeeId: employeeId,
        name: name,
      };
      // socketIO.emit("remotenotification")
      await dispatch(addWorkFromHome(newFormData));
      dispatch(fetchWorkFromHome(employeeId));
      toast.info('Record added successfully!');
    }
    socketIO.emit('remotenotification');
    setErrors({});
    setIsLoading(false);
    setShowModal(false);
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    try {
      await dispatch(deleteWorkFromHome(id));
      toast.info('Record Deleted Successfully');
    } catch (error) {
      console.error('Error deleting leave request:', error);
    }
    setIsLoading(false);
  };

  const handleDateChange = (date, field) => {
    if (date) {
      date = new Date(date);
    }
    setFormData({
      ...formData,
      [field]: date,
    });
  };

  const handleStatusChange = async (e, selectedLeaveId) => {
    const newStatus = e.target.value;
    try {
      await dispatch(
        updateWorkFromHome({
          id: selectedLeaveId,
          data: { status: newStatus, approvedBy: name },
        }),
      );
    } catch (error) {
      console.error(
        'Error updating leave request status:',
        error.response ? error.response.data : error.message,
      );
    }
  };

  DataTable.Buttons.jszip(JSZip);
  useEffect(() => {
    if (!$.fn.dataTable.isDataTable('.maindatatable')) {
      $('.maindatatable').DataTable({
        dom: 'flrtip',
        language: {
          search: '',
          searchPlaceholder: 'Search...',
          paginate: {
            previous: '«',
            next: '»',
          },
        },
      });
    }
  }, [dataa.length]);

  return (
    <>
      <div className='page-wrapper'>
        <div className='page-content'>
          <Breadcrumbb link={'Remote'} title={'Remote Work'} />

          <Container>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <ViewAllRemote
                      titlee={'Remote Work'}
                      options2={leads}
                      setEmployeeId={setEmployeeIdd}
                      apply={'Apply Remote'}
                      setFromReadMore={setFromReadMore}
                      handleShow={handleShow}
                    />

                    {dataa.length > 0 && (
                      <RemoteTable
                        dataa={dataa}
                        handleStatusChange={handleStatusChange}
                        handleDelete={handleDelete}
                        isLoading={isLoading}
                        handleShow={handleShow}
                        setFromReadMore={setFromReadMore}
                      />
                    )}
                    {dataa.length === 0 && (
                      <RemoteTable
                        dataa={dataa}
                        handleStatusChange={handleStatusChange}
                        handleDelete={handleDelete}
                        isLoading={isLoading}
                        handleShow={handleShow}
                        setFromReadMore={setFromReadMore}
                      />
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>

      <WorkHomeModal
        showModal={showModal}
        handleClose={handleClose}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        handleShow={handleShow}
        handleDateChange={handleDateChange}
        selectedLeaveId={selectedLeaveId}
        isLoading={isLoading}
        errors={errors}
        selectedEmployee={selectedEmployee}
        selectedLeads={selectedLeads}
        handleLeadChange={handleLeadChange}
        handleEmployeeChange={handleEmployeeChange}
        options2={leads}
        fromReadMore={fromReadMore}
        setFormData={setFormData}
        initialFormData={initialFormData}
      />
    </>
  );
}
