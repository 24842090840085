import { configureStore } from '@reduxjs/toolkit';
import usersReducer from './slices/Users';
import userSlice from './slices/userSlice';
import timesheet from './slices/Timesheet';
import Project from './slices/Project';
import loggedInUser from './slices/LoggedUser';
import leaveReducer from './slices/leaveSlice';
import workFromHomeReducer from './slices/RemoteWorkSlice';
import Lead from './slices/Lead';
// import loggedInUser from './slices/LoggedUser';
// import Lead from './slices/Lead';

const store = configureStore({
  reducer: {
    users: usersReducer,
    user: userSlice,
    timesheet: timesheet,
    project: Project,
    logged: loggedInUser,
    leave: leaveReducer,
    workFromHome: workFromHomeReducer,
    leads: Lead,
    // loggedUser: loggedInUser,
  },
});

export default store;
