import axios from 'axios';
// import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

// Create an instance of axios
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_PATH,
});

// Add a request interceptor to include the token in every request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token') || Cookies.get('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// function deleteCookie(name) {
//   document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
// }
// Add a response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // toast.error("Session expired. Please log in again.");
      // localStorage.removeItem("token");
      // localStorage.removeItem("admin");
      // localStorage.removeItem("lead");
      // localStorage.removeItem("firstName");
      // localStorage.removeItem("lastName");
      localStorage.clear();
      window.location.href = '/login';
      // setTimeout(() => {
      //   window.location.href = "/login";
      // }, 2000);
      toast.error('Session expired. Please log in again.');
      localStorage.clear();
      Cookies.remove('token');
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
