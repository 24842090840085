import React, { useState, useEffect, useRef } from 'react';
import { Container, Card } from 'react-bootstrap';
import Footer from '../layout/Footer';
import JSZip from 'jszip';
import Cookies from 'js-cookie';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import $ from 'jquery';
import Header from './Header';
import EditModal from './EditModel';
import DeleteModal from './DeleteModal';
import AddTimesheet from './AddTimesheet';

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllTimesheetData,
  newTimesheetRequestt,
  realAllTimesheets,
  seenTimesheetByAdmin,
} from '../../slices/Timesheet';
import Title from './components/Title';
import { fetchAllProjectData } from '../../slices/Project';
import { useNavigate } from 'react-router-dom';
import DataTable from 'datatables.net-buttons-dt';
import { fetchLeadsData } from '../../slices/Lead';

import Loader from '../common/Loader';

export default function Calendar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [expandedRowIndex] = useState(null);
  const textareaRef = useRef(null);
  const employeeIdFromRedux = useSelector((state) => state.logged.user.loggedUserInfo);
  const id = localStorage.getItem('employeeID') || employeeIdFromRedux;
  const [currentDate, setCurrentDate] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [addTimesheet, setAddTimesheet] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showModalThree, setShowModalThree] = useState(false);
  const [fromReadMore, setFromReadMore] = useState(false);
  const [employeeId, setEmployeeId] = useState(id);

  const admin = localStorage.getItem('admin') === 'true' ? true : false;
  const lead = localStorage.getItem('lead') === 'true' ? true : false;
  const hr = localStorage.getItem('hr') === 'true' ? true : false;

  const [showModalFour, setShowModalFour] = useState(false);
  const handleShowModalFour = () => setShowModalFour(true);
  // const handleCloseModalFour = () => setShowModalFour(false);
  const handleCloseEditModal = () => setShowEditModal(false);
  const handleCloseModalThree = () => setShowModalThree(false);
  const handleShowModalThree = () => setShowModalThree(true);

  const { Project } = useSelector((state) => state.project.allProjectData);
  const { leads } = useSelector((state) => state.leads.leadsData);
  const { Timesheet, loading } = useSelector((state) => state.timesheet.allTimesheetData);

  const isLead = localStorage.getItem('lead');
  const localEmployeeId = localStorage.getItem('employeeID');

  // const editDisabled = isLead && employee.id !== localEmployeeId ? 'disabled' :/

  const { unreadTimesheets } = useSelector((state) => state.timesheet.allTimesheetData);
  // console.log(unreadTimesheets, ' isNewtimesheetRequest from lef   unreadTimesheets  unreadTimesheets    ', isNewtimesheetRequest);

  // console.log(employeeId, ' employeei d employeei demployeei demployeei demployeei demployeei d00');

  useEffect(() => {
    const fetchTimesheetData = async () => {
      dispatch(newTimesheetRequestt(false));
      if (admin === true) {
        dispatch(fetchLeadsData());
      }
      try {
        const resultAction = dispatch(fetchAllTimesheetData({ employeeId, page: 1, limit: 10 }));

        const user = resultAction.payload;

        if (user === 'Unauthorized' || !localStorage.getItem('employeeID')) {
          localStorage.clear();
          Cookies.remove('token');

          navigate('/login');
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchTimesheetData();

    let access = false;
    if (
      localStorage.getItem('hr') === 'true' ||
      localStorage.getItem('admin') === 'true' ||
      localStorage.getItem('lead') === 'true'
    ) {
      access = true;
    }
    if (unreadTimesheets?.length > 0) {
      dispatch(realAllTimesheets({ employeeId, access }));
    }
    dispatch(seenTimesheetByAdmin());
  }, [dispatch, employeeId]);

  useEffect(() => {
    if (addTimesheet || showEditModal) {
      dispatch(fetchAllProjectData());
      dispatch(fetchLeadsData());
    }
  }, [dispatch, addTimesheet, showEditModal]);

  useEffect(() => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Note months are 0-indexed
    const year = today.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;
    setCurrentDate(formattedDate);
  }, []);

  DataTable.Buttons.jszip(JSZip);

  useEffect(() => {
    if (!$.fn.dataTable.isDataTable('.maindatatable')) {
      $('.maindatatable').DataTable({
        dom: 'flrtip',
        order: [[0, 'desc']],
        language: {
          search: '',
          searchPlaceholder: 'Search',
          paginate: {
            previous: '«',
            next: '»',
          },
        },
        columnDefs: [
          {
            targets: [0],
            visible: false,
            searchable: false,
          },
        ],
        paging: true,
        pageLength: 10,
        serverSide: true,
        ajax: {
          url: `${process.env.REACT_APP_API_BASE_PATH}/api/timesheets`,
          type: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token') || Cookies.get('token')}`,
          },
          data: function (d) {
            return {
              ...d,
              employee: employeeId,
              page: Math.ceil((d.start + d.length) / d.length),
              limit: d.length,
              search: d.search.value,
            };
          },
          dataSrc: function (json) {
            return json.data;
          },
        },
        columns: [
          { data: 'createdAt' },
          {
            data: 'date',
            render: function (data) {
              const timesheetDate = new Date(data)?.toLocaleDateString('en-GB');
              return timesheetDate;
            },
          },
          {
            data: 'employee',
            visible: lead || hr || admin,
          },
          {
            data: 'project',
            render: function (data) {
              return data
                .map(function (projectName) {
                  return `<div class="me-1">${projectName.label}</div>`;
                })
                .join(' ');
            },
          },
          {
            data: 'details',
            render: function (data, type, row) {
              const employee = row;

              function truncateContent(content, maxLines = 2, maxLength = 35) {
                const lineLength = 50; // Approximate number of characters per line
                const lines = content?.split('\n');

                if (lines?.length === 1 && content?.length <= maxLength) {
                  return content;
                } else if (lines?.length === 1 && content?.length > maxLength) {
                  return content?.slice(0, maxLength);
                } else if (lines?.length > 1) {
                  let truncatedContent = '';
                  let currentLineLength = 0;

                  for (let i = 0; i < lines?.length; i++) {
                    const line = lines[i];
                    currentLineLength += line?.length + 1;

                    if (currentLineLength <= lineLength) {
                      truncatedContent += line + '\n';
                    } else {
                      truncatedContent += line?.slice(0, lineLength - currentLineLength) + '...\n';

                      break;
                    }

                    if (i + 1 >= maxLines) {
                      break;
                    }
                  }

                  return truncatedContent.trim();
                }
              }

              if (type === 'display' && data && data.length > 50) {
                return (
                  '<span style=\'white-space: pre-line;\'>' +
                  truncateContent(data) +
                  '</span>' +
                  '<span ' +
                  'style="cursor: pointer; color: #1E90FF;" ' +
                  'onclick=\'handleShowEditModal(' +
                  JSON.stringify(employee) +
                  ' ,true)\'>' +
                  '....Read more' +
                  '</span>'
                );
              } else {
                return data;
              }
            },
          },

          {
            data: 'projectLead',
            render: function (data) {
              return data
                .map(function (lead) {
                  return `<badge class="badge bg-primary me-1 mb-1">${lead.label}</badge>`;
                })
                .join('');
            },
          },
          {
            data: null,
            visible: !admin,
            render: function (row) {
              // console.log("row 1111 => ", row);

              const employee = row;

              return (
                '<div class="d-flex">' +
                '<button ' +
                'class="btn btn-outline-primary btn-soft-primary me-2 edit-button" ' +
                'id="edit" ' +
                'style="padding:2.5px; font-size:0.8375rem;   height: 20px; width:20px;" ' +
                'onclick=\'handleShowEditModal(' +
                JSON.stringify(employee) +
                ' , false )\'>' +
                '<i class="bi bi-pencil-square" style=" font-size:0.8rem; line-height:0.5rem;"></i>' +
                '</button>' +
                '<button ' +
                'class="btn btn-outline-danger btn-soft-danger delete-button" ' +
                'style="padding:2.5px; font-size:0.8375rem;   height: 20px; width:20px;"' +
                'onclick=\'handleEmployeeDelete(' +
                JSON.stringify(employee) +
                ')\'>' +
                '<i class="bi bi-trash3" style=" font-size:0.8rem; line-height:0.5rem;"></i>' +
                '</button>' +
                '</div>'
              );
            },
          },
        ],
        initComplete: function (settings, json) {},
      });
    }
  }, [Timesheet, employeeId, lead, hr, admin, addTimesheet]);

  window.handleShowEditModal = function (employee, fromReadMore) {
    if (isLead === 'true') {
      if (employee.employeeId === localEmployeeId) {
        setSelectedEmployee(employee);
        setFromReadMore(fromReadMore);
        setShowEditModal(true);
      } else {
        setShowEditModal(false);
      }
    } else {
      setSelectedEmployee(employee);
      setFromReadMore(fromReadMore);
      setShowEditModal(true);
    }
  };

  window.handleEmployeeDelete = function (employee) {
    if (isLead === 'true') {
      if (employee.employeeId === localEmployeeId) {
        setSelectedEmployee(employee);
        handleShowModalThree();
      } else {
        // setShowEditModal(false);
      }
    } else {
      // setSelectedEmployee(employee);
      setSelectedEmployee(employee);
      handleShowModalThree();
      // setFromReadMore(fromReadMore);
      // setShowEditModal(true);
    }
  };

  return (
    <div>
      <div className='page-wrapper'>
        <div className='page-content'>
          <Header />
          <Container>
            {loading ? (
              <Loader />
            ) : (
              <Card>
                <Card.Body>
                  <Title
                    currentDate={currentDate}
                    handleAddTimesheetClick={() => setAddTimesheet(true)}
                    handleShowModalFour={() => setShowModalFour(true)}
                    setEmployeeId={setEmployeeId}
                    leads={leads}
                    employeeId={employeeId}
                  />

                  {addTimesheet === true && (
                    <AddTimesheet
                      Timesheet={Timesheet}
                      addTimesheet={addTimesheet}
                      handleShowModalFour={handleShowModalFour}
                      setAddTimesheet={setAddTimesheet}
                      currentDate={currentDate}
                      Project={Project}
                      leads={leads}
                    />
                  )}

                  {!addTimesheet && (
                    <div className='table-responsive'>
                      <table className='table table-bordered maindatatable my-2'>
                        <thead className='table-light'>
                          <tr>
                            <th scope='col'>Date Added</th>
                            <th scope='col'>Timesheet Date</th>
                            <th scope='col'>Employee Name</th>
                            <th scope='col'>Project</th>
                            <th scope='col'>Details</th>
                            <th scope='col'>Project Lead</th>
                            <th scope='col'>Actions</th>
                          </tr>
                        </thead>
                        <tbody>{/* DataTable handles the body content */}</tbody>
                      </table>
                    </div>
                  )}
                </Card.Body>
              </Card>
            )}
          </Container>
        </div>
        <Footer />
      </div>

      <EditModal
        show={showEditModal}
        handleClose={handleCloseEditModal}
        selectedEmployee={selectedEmployee}
        Project={Project}
        textareaRef={textareaRef}
        fromReadMore={fromReadMore}
        leads={leads}
      />

      <DeleteModal
        show={showModalThree}
        handleClose={handleCloseModalThree}
        selectedEmployee={selectedEmployee}
      />
    </div>
  );
}
