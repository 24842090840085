import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

const initialState = {
  userDetails: { userInfo: {}, loading: false, success: false, error: null },
};

// const employeeId = localStorage.getItem("employeeID")

export const loggedUserDetails = createAsyncThunk(
  'user/loggedUserDetails',
  async (employeeId, { rejectWithValue }) => {
    const token = localStorage.getItem('token') || Cookies.get('token');
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_PATH}/api/users/${employeeId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      // console.log(response.data, "response.data");
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        console.log(error.response.status, 'error.response.status');
        return rejectWithValue('Unauthorized');
      } else {
        throw error;
      }
    }
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserProfile(state, action) {
      const user = action.payload;

      state.userDetails.userInfo = user;

      state.userDetails.info = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loggedUserDetails.pending, (state) => {
        state.userDetails.loading = true;
        state.userDetails.error = null;
      })
      .addCase(loggedUserDetails.fulfilled, (state, action) => {
        state.userDetails.loading = false;
        state.userDetails.userInfo = action.payload;
      })
      .addCase(loggedUserDetails.rejected, (state, action) => {
        state.userDetails.loading = false;
        state.userDetails.error = action.error.message;
      });
  },
});

export default userSlice.reducer;
export const { updateUserProfile } = userSlice.actions;
