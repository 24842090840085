import React from 'react';
import { Button, Card } from 'react-bootstrap';

export default function AddEmployeeButton({ setAdd, handleShow }) {
  const hr = localStorage.getItem('hr');
  return (
    <div className='d-md-flex justify-content-between align-items-center mb-3'>
      <Card.Title className='mb-0'>Employee List</Card.Title>
      {hr === 'true' ? (
        <div>
          <Button
            variant='soft-primary ms-lg-3'
            style={{ padding: '0.3rem 0.7rem', fontSize: '1rem' }}
            onClick={() => {
              setAdd(true);
              handleShow();
            }}
          >
            <i className='bi bi-plus-circle me-2'></i>Add Employee
          </Button>
        </div>
      ) : null}
    </div>
  );
}
