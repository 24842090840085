import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { socketIO } from '../App';
import { seenRequestidss } from '../slices/RemoteWorkSlice';

export const RemoteSocketClient = () => {
  const isAdminOrLead =
    localStorage.getItem('admin') === 'true' ||
    localStorage.getItem('lead') === 'true' ||
    localStorage.getItem('hr') === 'true';
  const dispatch = useDispatch();

  // console.log('isAdminOrLead')
  useEffect(() => {
    // console.log('isAdminOrLead2d',isAdminOrLead)

    socketIO.on('pendingremoteRequestt', () => {
      // console.log('isAdminOrLeadggggggggggg', isAdminOrLead)
      if (isAdminOrLead) {
        // dispatch(newRemoteRequest(true));
        dispatch(seenRequestidss());
      }
    });
    return () => {
      socketIO.off('pendingremoteRequestt');
    };
  }, [dispatch]);

  return null;
};
