import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { socketIO } from '../App';
import { seenRequestids } from '../slices/leaveSlice';

export const SocketClient = () => {
  const isAdminOrLead =
    localStorage.getItem('admin') === 'true' ||
    localStorage.getItem('lead') === 'true' ||
    localStorage.getItem('hr') === 'true';
  const dispatch = useDispatch();

  useEffect(() => {
    socketIO.on('pendingLeaveRequest', () => {
      if (isAdminOrLead) {
        dispatch(seenRequestids());
        // dispatch(newRequest(true));
        // dispatch(fetchLeaves(employeeId))
      }
    });
    return () => {
      socketIO.off('pendingLeaveRequest');
    };
  }, [dispatch]);

  return null;
};
