import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
const calculateLeaves = (records) => {
  const totalPaidLeaves = 13;
  let totalUnpaidLeaves = 0;
  let remainingPaidLeaves = totalPaidLeaves;
  let leavesPerMonth = {};

  // console.log(records, ' the records to see thhatr  ');
  records.forEach((record) => {
    // console.log(record.leaveto, ' to see the console  ');
    if (record.status === 'Approved') {
      const month = new Date(record.leaveto).getMonth();
      if (!leavesPerMonth[month]) {
        leavesPerMonth[month] = 0;
      }
      leavesPerMonth[month] += record.noofdays;
    }
  });

  let paidLeavesTaken = 0;
  // Calculate paid and unpaid leaves for each month
  for (let month in leavesPerMonth) {
    const monthlyLeaves = leavesPerMonth[month];
    if (monthlyLeaves > 1) {
      paidLeavesTaken += 1; // Only 1 leave is paid per month
      totalUnpaidLeaves += monthlyLeaves - 1; // Rest are unpaid
    } else {
      paidLeavesTaken += monthlyLeaves;
    }
  }
  // Calculate remaining paid leaves
  remainingPaidLeaves = Math.max(totalPaidLeaves - paidLeavesTaken, 0);
  // Calculate total leaves for the current year
  const totalLeavesThisYear = Object.values(leavesPerMonth).reduce((acc, val) => acc + val, 0);
  return {
    totalPaidLeaves,
    remainingPaidLeaves,
    totalUnpaidLeaves,
    totalLeavesThisYear,
  };
};
// Function to group leave records by employee name
const groupRecordsByEmployee = (records) => {
  const groupedRecords = {};
  records?.forEach((record) => {
    if (!groupedRecords[record.employeeName]) {
      groupedRecords[record.employeeName] = [];
    }
    groupedRecords[record.employeeName].push(record);
  });
  return groupedRecords;
};
// Component to display leave statistics in card format
const LeaveCardGroup = ({ leaveRecords, employeeName }) => {
  const groupedRecords = groupRecordsByEmployee(leaveRecords);
  const employeeRecords = groupedRecords[employeeName] || [];
  const { totalPaidLeaves, remainingPaidLeaves, totalUnpaidLeaves, totalLeavesThisYear } =
    calculateLeaves(employeeRecords);
  // Array of card data to be displayed
  const cards = [
    {
      count: totalPaidLeaves,
      label: 'Total Paid Leaves',
      icon: 'bi-cash-coin',
      color: 'primary',
    },
    {
      count: remainingPaidLeaves,
      label: 'Remaining Paid Leaves',
      icon: 'bi-clock-history',
      color: 'warning',
    },
    {
      count: totalUnpaidLeaves,
      label: 'Total Unpaid Leaves',
      icon: 'bi-file-minus-fill',
      color: 'info',
    },
    {
      count: totalLeavesThisYear,
      label: 'Total Leaves This Year',
      icon: 'bi-calendar',
      color: 'success',
    },
  ];
  return (
    <Row>
      {cards.map((card, index) => (
        <Col className='mb-4' md={6} xxl={3} key={index}>
          <Card className='mb-0 h-100'>
            <Card.Body>
              <div className='d-flex align-items-center'>
                <div className={`avatar avatar-lg bg-soft-${card.color} text-${card.color}`}>
                  <i className={`bi ${card.icon} fs-20`}></i>
                </div>
                <div className='ms-3'>
                  <h6 className={`text-${card.color} fw-semibold mb-1`}>{card.count}</h6>
                  <h6 className='mb-0'>{card.label}</h6>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};
export default LeaveCardGroup;
