import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export default function FamilyInfoComponent({ userInfo }) {
  return (
    <Card>
      <Card.Body>
        <Card.Title>Family Info</Card.Title>
        <Row>
          <Col lg={6} md={6} xl={4}>
            <div className='mb-2'>
              <p className='text-muted fs-13 mb-1'>Family Member1 Name </p>
              <h5 className='text-dark fs-15 mb-0'>{userInfo?.familyMember1FullName}</h5>
            </div>
          </Col>
          <Col lg={6} md={6} xl={4}>
            <div className='mb-2'>
              <p className='text-muted fs-13 mb-1'>Family Member1 Relation</p>
              <h5 className='text-dark fs-15  mb-0'>{userInfo?.familyMember1Relation}</h5>
            </div>
          </Col>

          <Col lg={6} md={6} xl={4}>
            <div className='mb-2'>
              <p className='text-muted fs-13 mb-1'> Family Member1 Mobile No.</p>
              <h5 className='text-dark fs-15 mb-0'>{userInfo?.familyMember1MobileNo}</h5>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} xl={4}>
            <div>
              <p className='text-muted fs-13 mb-1'>Family Member2 Name </p>
              <h5 className='text-dark fs-15 mb-0'>{userInfo?.familyMember2FullName}</h5>
            </div>
          </Col>
          <Col lg={6} md={6} xl={4}>
            <div>
              <p className='text-muted fs-13 mb-1'>Family Member2 Relation</p>
              <h5 className='text-dark fs-15 mb-0'>{userInfo?.familyMember2Relation}</h5>
            </div>
          </Col>

          <Col lg={6} md={6} xl={4}>
            <div>
              <p className='text-muted fs-13 mb-1'> Family Member2 Mobile No.</p>
              <h5 className='text-dark fs-15 mb-0'>{userInfo?.familyMember2MobileNo}</h5>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
