import React, { useState } from 'react';
import { Badge, Table } from 'react-bootstrap';
// import AddEditButton from "../../common/AddEditButton";
import RemoteRow from './RemoteRow';

export default function RemoteTable({
  dataa,
  setFromReadMore,
  handleDelete,
  handleStatusChange,
  isLoading,
  handleShow,
}) {
  const [expandedRowIndex] = useState(null);
  const isAdmin = localStorage.getItem('admin') === 'true';

  function truncateContent(content, maxLines = 2, maxLength = 35) {
    const lineLength = 50;
    const lines = content?.split('\n');

    if (lines?.length === 1 && content?.length <= maxLength) {
      return content;
    } else if (lines?.length === 1 && content?.length > maxLength) {
      return content?.slice(0, maxLength);
    } else if (lines?.length > 1) {
      let truncatedContent = '';
      let currentLineLength = 0;

      for (let i = 0; i < lines?.length; i++) {
        const line = lines[i];
        currentLineLength += line?.length + 1;

        if (currentLineLength <= lineLength) {
          truncatedContent += line + '\n';
        } else {
          truncatedContent += line?.slice(0, lineLength - currentLineLength) + '...\n';
          break;
        }

        if (i + 1 >= maxLines) {
          break;
        }
      }

      return truncatedContent.trim();
    }
  }
  return (
    <div>
      <Table responsive bordered className='mb-0 table-nowrap maindatatable' size='sm'>
        <thead className='table-light'>
          <tr>
            {(isAdmin && localStorage.getItem('hr') === 'false') ||
            (!isAdmin && localStorage.getItem('hr') === 'true') ||
            (!isAdmin && localStorage.getItem('lead') === 'true') ? (
              <td style={{ fontSize: '12.8px', fontWeight: '500' }}>Name</td>
            ) : null}

            <th>Request Date</th>

            <th>Duration</th>

            {/* <th>Remote End Date</th> */}

            <th>Status</th>

            {(isAdmin && localStorage.getItem('hr') === 'false') ||
            (!isAdmin && localStorage.getItem('hr') === 'true') ||
            (!isAdmin && localStorage.getItem('lead') === 'true') ? (
              <th>Marked By</th>
            ) : null}

            {isAdmin && localStorage.getItem('hr') === 'false' && <th>Applied By</th>}

            {!isAdmin && localStorage.getItem('hr') === 'false' && <th>Reason</th>}

            <th>No of days</th>

            <th>Leader Name</th>

            {(isAdmin && localStorage.getItem('hr') === 'false') ||
            (!isAdmin && localStorage.getItem('hr') === 'true') ||
            (!isAdmin && localStorage.getItem('lead') === 'true') ? (
              <th>Admin Action</th>
            ) : null}

            {(!isAdmin && localStorage.getItem('lead') === 'false') ||
            (!isAdmin && localStorage.getItem('lead') === 'true') ||
            (!isAdmin && localStorage.getItem('hr') === 'true') ? (
              <th>Action</th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {dataa?.map((data) => (
            //   <tr key={i}>
            //      {(isAdmin &&
            //       localStorage.getItem("hr") === "false") ||
            //     (!isAdmin &&
            //       localStorage.getItem("hr") === "true") ||
            //     (!isAdmin && localStorage.getItem("lead") === "true") ? (
            //       <td>{data.fromAdmin ? data?.employeename?.label : data?.name}</td>
            //     ) : null}

            //     <td>
            //       {new Date(data?.requestDate).toLocaleDateString()}
            //     </td>

            //     <td>
            //       {new Date(
            //         data?.remoteStartDate
            //       ).toLocaleDateString()}
            //     </td>

            //     <td>
            //       {new Date(
            //         data?.remoteEndDate
            //       ).toLocaleDateString()}
            //     </td>

            //     <td>
            //       <span
            //         className={`badge ${
            //           data.status === "Pending"
            //             ? "bg-soft-warning"
            //             : data.status === "Approved"
            //             ? "bg-soft-success"
            //             : "bg-soft-danger"
            //         } text-dark`}
            //       >
            //         {data.status}
            //       </span>
            //     </td>

            //     {(isAdmin &&
            //       localStorage.getItem("hr") === "false") ||
            //     (!isAdmin &&
            //       localStorage.getItem("hr") === "true") ||
            //     (!isAdmin && localStorage.getItem("lead") === "true") ? (
            //       <td>{data?.approvedBy}</td>
            //     ) : null}

            //       {isAdmin &&
            //         localStorage.getItem("hr") === "false" && (
            //           <td>
            //             {data.fromAdmin ? data.name : data.name}
            //           </td>
            //         )}

            //     {!isAdmin &&
            //       localStorage.getItem("hr") === "false" && (
            //         <td
            //           style={{
            //             whiteSpace: "pre-line",
            //             wordWrap: "break-word",
            //             wordBreak: "break-word",
            //           }}
            //         >
            //           {data.reason}
            //         </td>
            //       )}

            //     <td>{data.noofdays}</td>

            //     <td>
            //       {data?.lead?.map((lead, index) => (
            //         <Badge
            //           key={index}
            //           pill
            //           bg="primary"
            //           className="me-1"
            //         >
            //           {lead.label}
            //         </Badge>
            //       ))}
            //     </td>

            //     {(isAdmin &&
            //       localStorage.getItem("hr") === "false") ||
            //     (!isAdmin &&
            //       localStorage.getItem("hr") === "true") ||
            //     (!isAdmin && localStorage.getItem("lead") === "true") ? (
            //       <td>
            //         <select
            //           className="form-select small-select"
            //           value={data.statusText}
            //           onChange={(e) =>
            //             handleStatusChange(e, data._id)
            //           }
            //         >
            //           <option value="Requested">Select</option>
            //           <option value="Approved">✔️</option>
            //           <option value="Rejected">❌</option>
            //         </select>
            //       </td>
            //     ) : null}

            // {(!isAdmin &&
            //     localStorage.getItem("hr") === "false") ||
            //   (!isAdmin &&
            //     localStorage.getItem("hr") === "true") ? (
            //       <td>
            //         <AddEditButton
            //           isLoading={isLoading}
            //           handleShow={handleShow}
            //           data={data}
            //           handleDelete={handleDelete}
            //         />
            //       </td>
            //     ):null}
            //   </tr>

            <RemoteRow
              key={data?.id}
              data={data}
              handleDelete={handleDelete}
              handleStatusChange={handleStatusChange}
              handleShow={handleShow}
              isAdmin={isAdmin}
              isLoading={isLoading}
              setFromReadMore={setFromReadMore}
              truncateContent={truncateContent}
              expandedRowIndex={expandedRowIndex}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
}
