import React from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';

const Header = () => (
  <Container>
    <Row>
      <Col>
        <div className='d-flex justify-content-between align-items-center page-title-content'>
          <h3 className='page-title' style={{ fontSize: '17px' }}>
            Welcome {localStorage.getItem('firstName')}
          </h3>
          <Breadcrumb className='d-none d-md-block'>
            <Breadcrumb.Item href='/' style={{ fontSize: '15px' }}>
              HRM
            </Breadcrumb.Item>
            <Breadcrumb.Item active style={{ fontSize: '15px' }}>
              TimeSheet
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </Col>
    </Row>
  </Container>
);

export default Header;
