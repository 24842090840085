import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = () => {
  return (
    <div className='d-flex justify-content-center flex-column align-items-center'>
      <Spinner
        animation='border'
        role='status'
        style={{
          width: '100px',
          height: '100px',
          margin: 'auto',
          display: 'block',
          color: '#0047af',
        }}
      ></Spinner>
      <span className='sr-only mt-4' style={{ color: '#0047af' }}>
        Loading....
      </span>
    </div>
  );
};

export default Loader;
