import React, { useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { addProject, fetchAllProjectData } from '../../slices/Project';
import { useDispatch } from 'react-redux';
import { validateProject } from '../common/Joi_Validation';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

import { useNavigate } from 'react-router-dom';
import { socketIO } from '../../App';

export default function AddProject({ showModalThree, handleCloseModalThree, options, options1 }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [loadingAddProject, setLoadingAddProject] = useState(false);
  const [formData, setFormData] = useState({
    projectName: '',
    projectStatus: null, // Initially set to null to avoid potential errors
    projectLead: [],
    createdBy: localStorage.getItem('firstName'),
  });

  const handleSubmit = async (event) => {
    const validateErrors = validateProject(formData);
    // console.log(errors, 'errors');
    if (Object.keys(validateErrors).length > 0) {
      setErrors(validateErrors);
      return;
    }
    try {
      if (!loadingAddProject) {
        setLoadingAddProject(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_PATH}/api/projects`, // Assuming endpoint for creating projects
          formData,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token') || Cookies.get('token')}`,
            },
          },
        );
        // console.log('response from projetc for lead', response);
        dispatch(addProject(response.data)); // Assuming response contains project data
        dispatch(fetchAllProjectData());
        socketIO.emit('notificationn');
        toast.info('Project created successfully');
        setFormData({
          projectName: '',
          projectStatus: null,
          projectLead: null,
        });
        // socketIO.emit("notificationn")
        setErrors({});
        setLoadingAddProject(false);
      }
      // socketIO.emit("notificationn")
      handleCloseModalThree(); // Close modal on successful creation
    } catch (error) {
      if (!loadingAddProject) {
        toast.error('Project creation failed');
        console.error('Error creating project:', error);
        setLoadingAddProject(false);
        if (error.response?.status === 401) {
          // console.log(error.response.status, 'error.response.status');
          localStorage.clear();
          Cookies.remove('token');

          navigate('/login');
        }
      }
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData({ ...formData, [name]: selectedOption });
  };

  // console.log("options1", options1)
  return (
    <Modal
      centered
      show={showModalThree}
      onHide={() => {
        handleCloseModalThree();
        setFormData({});
        setErrors({});
      }}
      dialogClassName='modal-lg'
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={12}>
              <Form.Group className='custom-fr-group'>
                <Form.Label>Project Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter project name...'
                  required
                  name='projectName'
                  id='projectName'
                  value={formData.projectName}
                  onChange={handleChange}
                />
                {errors.projectName && (
                  <p className='text-danger'>Project Name is not allowed to be empty</p>
                )}
              </Form.Group>
            </Col>
            <Col md={12} className='mb-3'>
              <Form.Group className='custom-form-input'>
                <Form.Label>Select Status</Form.Label>
                <Select
                  options={options}
                  placeholder='@ Select Status...'
                  name='projectStatus'
                  value={formData.projectStatus} // Set initial selected value
                  onChange={handleSelectChange}
                />
                {errors.projectStatus && (
                  <p className='text-danger'>Project Status must be of type object</p>
                )}
              </Form.Group>
            </Col>
            <Col md={12} className='mb-3'>
              <Form.Group className='custom-form-input'>
                <Form.Label>Select Project Lead</Form.Label>
                <Select
                  isMulti
                  options={options1}
                  placeholder='@ Select Lead...'
                  name='projectLead'
                  value={formData.projectLead} // Set initial selected value
                  onChange={handleSelectChange}
                />
                {errors.projectLead && <p className='text-danger'>{errors.projectLead}</p>}
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer className='border-0'>
        <Button
          style={{ padding: '0.3rem 0.7rem', fontSize: '1rem' }}
          variant='primary'
          type='submit'
          disabled={loadingAddProject}
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          {loadingAddProject ? 'Submitting...' : 'Submit'}
        </Button>
        <Button
          style={{ padding: '0.3rem 0.7rem', fontSize: '1rem' }}
          variant='secondary'
          onClick={() => {
            handleCloseModalThree();
            setFormData({});
            setErrors({});
          }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
